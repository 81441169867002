<template>
  <div
    class="chat flex flex-col w-full mb-4"
    ref="chat"
    @click="onClickDisableUserSearch"
    @click.self="
      this.currentUser = {};
      currentUserMessages = {};
    "
  >
    <div class="w-full">
      <div class="container-page">
        <title-page-component :routBackView="false">
          <template v-slot:input>
            <takeout-layout @click.stop takeout="left">
              <input-field
                @click="onClickEnableUserSearch"
                @input="isSearchOpen = true"
                class="input-component w-80"
                type="search"
                v-model:value="search"
                placeholder="Введите ФИО собеседника"
              />

              <div class="select__options" v-show="isSearchOpen">
                <ul class="select__options-list">
                  <li v-for="name in filteredNames">
                    <span @click="onClickSetSearchedUserActive(name.id)">{{
                      name.fullName
                    }}</span>
                  </li>
                </ul>
              </div>
            </takeout-layout>
          </template>

          <template v-slot:title>
            <p :class="[!currentUser.name ? 'opacity-0' : '']">
              Чат с <span class="title-span">{{ currentUser.name }}</span>
            </p>
          </template>
        </title-page-component>
      </div>
    </div>

    <div class="flex gap-24 container-page h-[100%]">
      <div class="spinner" v-if="isLoading"></div>
      <div
        class="flex flex-col gap-3 w-64 shrink-0 text-xs h-full pb-[10px] overflow-auto pl-2.5 min-h-[64vh] max-h-[64vh] overflow-y-auto"
      >
        <div v-for="user of this.userList" :key="user.id">
          <div
            @click="onClickSetUserActive(user)"
            class="flex gap-3 items-center cursor-pointer pt-[10px]"
          >
            <div
              class="avatar w-[3.25rem] h-[3.25rem] rounded-full ring-main shrink-0 relative"
              :class="[
                user.id === this.currentUser.id ? 'ring-[10px] z-99' : '',
              ]"
            >
              <img
                class="rounded-full w-auto h-[100%]"
                :src="user.image"
                v-if="user.image"
                alt=""
              />
<!--              <button-->
<!--                @click.stop-->
<!--                class="bg-main rounded-full p-1 absolute right-0 top-0"-->
<!--              >-->
<!--                <img class="w-3 h-3" src="@/assets/images/svg/closeCross.svg" />-->
<!--              </button>-->
            </div>
            <div class="flex flex-col gap-1">
              <span class="font-bold"
                >{{ user.surname }} {{ user.name }} <br />
                {{ user.patronymic }}</span
              >
              <span class="text-gray-500">{{ user.workshops }}</span>
            </div>
            <div
              v-show="user.unreadMessageCount"
              class="px-1.5 py-0.5 rounded-3xl bg-main text-white font-bold self-start"
            >
              {{ user.unreadMessageCount }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex flex-column gap-8 w-full overflow-y-auto max-h-[63vh] px-2"
        ref="scrollContainer"
        @click="uploadCurrentUserMessages"
        @scroll="handleScroll"
      >
        <div
          v-for="(messages, key) of currentUserMessages"
          :key="key"
          class="w-full flex flex-col gap-4"
        >
          <p class="align-self-center">{{ messageDate(key) }}</p>
          <div
            v-for="singleMessage of messages"
            :class="[singleMessage.isMyMessage ? 'self-end' : 'self-start']"
            class="message"
          >
            <div
              :class="[singleMessage.isMyMessage ? 'hidden' : '']"
              class="relative text-lg font-bold max-w-xl bg-main py-2 px-9 rounded-3xl"
            >
              <div
                class="absolute !border-l-main border-l-[0.75rem] bottom-full border-t-[0.75rem] border-transparent left-6"
              ></div>
              <p>
                {{ !singleMessage.isMyMessage ? singleMessage.text : "" }}
              </p>
            </div>
            <div
              :class="[!singleMessage.isMyMessage ? 'hidden' : '']"
              class="relative bg-white text-lg font-bold max-w-xl self-end ring-2 ring-inset ring-main py-2 px-9 rounded-3xl"
            >
              <div
                class="absolute !border-r-main border-r-[0.75rem] bottom-full border-t-[0.75rem] border-transparent right-6 before:-bottom-[3px] before:left-0 before:border-transparent before:border-r-white before:absolute before:border-t-[0.625rem] before:border-r-[0.625rem]"
              ></div>
              <p>
                {{ singleMessage.isMyMessage ? singleMessage.text : "" }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-chat w-full">
      <div class="container-page !pb-5">
        <takeout-layout takeout="left">
          <div class="flex items-center w-full">
            <input-field
              @search="onClickSendMessage"
              class="input-component w-full"
              type="text"
              v-model:value="message"
              placeholder="Сообщение"
            />
            <div class="w-16 h-2 bg-main"></div>
            <div class="takeout-circle_left relative">
              <button-component
                disabled
                class="!w-56 !gap-3"
                @click="onClickSendMessage"
              >
                <template v-slot:icon-left>
                  <img
                    src="@/assets/images/svg/restore.svg"
                    class="text-white"
                    alt=""
                  />
                </template>
                Отправить
              </button-component>
            </div>
          </div>
        </takeout-layout>
      </div>
    </div>
  </div>
</template>

<script>
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
import TitlePageComponent from "@/components/ui/TitlePageComponent.vue";
import InputField from "@/components/ui/InputField.vue";
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import api from "@/common/api/api";
import axios from "axios";
import * as signalR from "@microsoft/signalr";

export default {
  name: "Home",
  components: {
    TitlePageComponent,
    TakeoutLayout,
    InputField,
    ButtonComponent,
  },
  data() {
    return {
      currentUser: {},
      currentUserMessages: {},
      search: "",
      message: "",
      userList: [],
      isSearchOpen: false,
      isLoading: true,

      limit: null,
      offset: null,

      scrollHeight: null,
    };
  },
  created() {
    this.getChatUsers();
  },
  methods: {
    getChatUsers() {
      api.getChatUsers().then((res) => {
        this.userList = res.data;
        this.isLoading = false;
      });
    },
    onClickEnableUserSearch() {
      this.isSearchOpen = !this.isSearchOpen;
    },
    onClickDisableUserSearch() {
      this.isSearchOpen = false;
    },
    async getCurrentUserMessages() {
      const { data } = await axios.get(`/Chat/Messages/${this.currentUser.id}`);

      this.currentUserMessages = data;
      this.limit = 10;
      this.offset = 1;

      await this.$nextTick();
      const container = this.$refs.scrollContainer;
      container.scrollTop = container.scrollHeight;

      this.scrollHeight = container.scrollHeight;
    },
    async uploadCurrentUserMessages() {
      this.offset = this.offset + this.limit;
      const { data } = await axios.get(
        `/Chat/Messages/${this.currentUser.id}`,
        {
          params: {
            limit: this.limit,
            offset: this.offset,
          },
        },
      );
      function mergeArrays(obj1, obj2) {
        const result = { ...obj1 };

        for (const key in obj2) {
          if (Object.prototype.hasOwnProperty.call(obj2, key)) {
            if (Object.prototype.hasOwnProperty.call(result, key)) {
              result[key] = result[key].concat(obj2[key]);
            } else {
              result[key] = obj2[key];
            }
          }
        }
        return result;
      }
      this.currentUserMessages = mergeArrays(data, this.currentUserMessages);

      await this.$nextTick();
      const container = this.$refs.scrollContainer;
      container.scrollTop = container.scrollHeight - this.scrollHeight;
      this.scrollHeight = container.scrollHeight
    },
    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (container?.scrollTop <= 1) {
        this.uploadCurrentUserMessages();
      }
    },
    onClickSetUserActive(user) {
      this.currentUser = user;
      this.getCurrentUserMessages();
    },
    onClickSetSearchedUserActive(id) {
      const userToMatch = this.userList.filter((el) => el.id === id);
      this.onClickSetUserActive(userToMatch[0]);
    },
    messageDate(isoString) {
      let dateObj = new Date(isoString);

      let day = dateObj.getDate();
      let month = dateObj.getMonth() + 1;
      let year = dateObj.getFullYear();

      const monthNames = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ];
      month = monthNames[month - 1];

      return day + " " + month + " " + year;
    },
    onClickSendMessage() {
      if (this.message) {
        axios
          .post(
            `/Chat/SendMessage/${this.currentUser.id}`,
            JSON.stringify(this.message),
            {
              headers: { "Content-Type": "application/json" },
            },
          )
          .catch((error) => {
            console.error(error);
          });
        this.getCurrentUserMessages();
        this.message = "";
        setTimeout(() => {
          this.getCurrentUserMessages();
        }, 0);
      }
    },
  },

  computed: {
    filteredNames() {
      const usersArray = this.userList.reduce((acc, el) => {
        acc.push({
          fullName: el.name + " " + el.patronymic + " " + el.surname,
          id: el.id,
        });
        return acc;
      }, []);
      return usersArray.filter((el) =>
        el?.fullName.toLowerCase().includes(this.search.toLowerCase()),
      );
    },
  },
  mounted: function () {
    const signalRConnection = new signalR.HubConnectionBuilder()
      .withUrl(`https://lic239.test.runetsoft.ru/signalrHubs/chatHub`, {
        withCredentials: false,
        accessTokenFactory: () => localStorage.getItem("token"),
        skipNegotiation: true,
      })
      .withAutomaticReconnect()
      .build();

    async function connectionStart() {
      try {
        await signalRConnection.start();
      } catch (err) {
        console.log(err);
        setTimeout(connectionStart, 5000);
      }
    }

    signalRConnection.onclose(async () => {
      await connectionStart();
    });

    connectionStart();

    signalRConnection.on("ReceiveNotification", () => {});

    const connection = new signalR.HubConnectionBuilder()
      .withUrl("https://lic239.test.runetsoft.ru/signalrHubs/chatHub", {})
      .withAutomaticReconnect()
      .build();

    if ("Notification" in window) {
      if (Notification && Notification.permission !== "granted") {
        console.log("Request notification permissions");
        Notification.requestPermission();
      }
    } else {
      // API not supported
    }
    console.log(connection);

    const table = this.$refs.chat;
    const rect = table.getBoundingClientRect();
    this.tableStartY = rect.top + window.scrollY;
    table.style.maxHeight = `calc(100vh - ${this.tableStartY + 10}px)`;
  },
};
</script>

<style lang="scss" scoped>
.takeout-circle {
  &_left::before,
  &_right::after {
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    content: "";
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    border: 10px solid #ffce38;
    box-shadow: rgba(0, 0, 0, 0.49) 0 0 1px;
  }
  &_left::before {
    left: -15px;
  }

  &_right::after {
    right: -15px;
  }
}
.title {
  flex: 1;
  justify-self: flex-start;
  font-size: 40px;
  line-height: 55px;
  font-weight: 400;
}
.title-span {
  font-weight: 700;
  color: #ffce38;
}

.select-wrapper {
  display: flex;
  align-items: center;
}
.select {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__label {
    position: absolute;
    color: #c6c6c6;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 0;
    z-index: 4;
    pointer-events: none;
    top: 33%;
    left: 30px;

    &_error {
      color: red;
    }
  }
  &__container {
    position: relative;
    display: flex;
    align-items: center;
  }
  &__selected-content {
    max-height: 20px;
    overflow: hidden;
  }
  &__dropdown {
    height: 52px;
    z-index: 3;
    background: white;
    width: 100%;
    border: 4px solid #ffce38;
    border-radius: 30px;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:focus {
      box-shadow: #ffce38 0 0 20px;
      border-color: #ffce38;
    }
  }

  &__arrow {
    transition: transform 0.2s;
    transform: rotate(0deg);

    &--open {
      transform: rotate(180deg);
    }
  }

  &__options {
    max-height: 200px;
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: 2;
    position: absolute;
    top: 60%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 4px solid #ffce38;
    border-top: none;
    border-radius: 0 0 30px 30px;
    list-style: none;
    padding: 2px;
    padding-top: 15px;

    margin: 0;

    &-list {
      max-height: 200px;
      overflow-y: auto;
      padding: 5px;
    }
    li {
      padding: 10px 20px;
      cursor: pointer;

      &:hover {
        background-color: #ffce38;
      }
    }
  }
}
.z-index {
  z-index: 5;
}
.chat {
}
.form-chat {
  //position: fixed;
  //bottom: 0;
}
.message {
  -webkit-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
  word-break: break-word;
}
.input-component {
  z-index: 3;
}
.avatar {
  background-image: url("@/assets/images/avatar.png");
  background-size: cover; /* растянуть изображение на всю ширину и высоту блока */
  background-position: center; /* центрировать изображение */
}
.spinner {
  position: absolute;
  top: 50%;
  left: 43%;
  transform: translate(-50%, -50%);
  border: 6px solid rgba(255, 255, 255, 0.3);
  border-top: 6px solid #ffce38;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>