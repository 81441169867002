import axios from "axios";

export default {
    namespaced: true,
    state: () => ({
        stateKit: null,
        stateItem: null,
        typeKit: null,
        typeItem: null,
        classroom: null,
        grade: null,
        workshop: null,
        userGroup: [
            { id: 1, title: 'Суперпользователь', code: 'Superuser'},
            { id: 2, title: 'Администратор', code: 'Admin'},
            { id: 3, title: 'Преподаватель', code: 'Teacher'},
            { id: 4, title: 'Ученик', code: 'Student'}
        ]
    }),
    mutations: {
        setStateKit(state, res) {
            state.stateKit = res.data
        },
        setStateItem(state, res) {
            state.stateItem = res.data
        },
        setTypeKit(state, res) {
            state.typeKit = res.data
        },
        setClassRoom(state, res) {
            state.classroom = res.data
        },
        setItemType(state, res) {
            state.typeItem = res.data
        },
        setGrade(state, res) {
            state.grade = res.data
        },
        setWorkshop(state, res) {
            state.workshop = res.data
        },
        },
    getters: {
    },
    actions: {
        getStateKit(context) {
            axios.get('nsi/kitState')
                .then(res => {
                    context.commit('setStateKit', res)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getStateItem(context) {
            axios.get('nsi/itemState')
                .then(res => {
                    context.commit('setStateItem', res)
                })
        },

        getTypeKit(context) {
       axios.get('nsi/kitType')
           .then(res => {
               context.commit('setTypeKit', res)
           })
           .catch(err => {
               console.log(err)
           })
   },
        getClassRoom(context) {
         axios.get('nsi/classroom')
             .then(res => {
                 context.commit('setClassRoom', res)
             })
             .catch(err => {
                 console.log(err)
             })
     },
        getItemType(context) {
            axios.get('nsi/itemType')
                .then(res => {
                    context.commit('setItemType', res)
                })
        },
        getGrade(context) {
            axios.get('nsi/grade')
                .then(res => {
                    context.commit('setGrade', res)
                })
        },
        getWorkshop(context) {
            axios.get('nsi/workshop')
                .then(res => {
                    context.commit('setWorkshop', res)
                })
        },
    }
}