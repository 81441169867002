<template>
  <div class="lk container-page" v-if="itemData">
    <title-page-component>
      <template v-slot:title>
        Карточка комплектующего <span class="title-span">{{ itemData.code }}</span>
      </template>
    </title-page-component>
    <div class="flex justify-between text-3xl mb-12 items-center">
      Атрибуты комплектующего
    </div>
    <div class="flex gap-10 items-center justify-between mb-16">
      <div class="flex flex-col gap-[0.5rem]">

        <div class="flex justify-between ml-[55px] text-3xl align-self-center items-center">

          <div v-if="itemData.stateId === 1" class="flex gap-1 items-center">
            <div class="rounded-full border-[10px] border-main">
              <img src="@/assets/images/svg/partyboto.svg" class="w-20 h-20" alt="">
            </div>

            <div class="flex flex-col text-2xl">
              <p>Статус:</p>
              <p>в наборе</p>
            </div>
          </div>

          <div v-else class="flex gap-1 items-center">
            <div class="rounded-full border-[10px] border-main">
              <img src="@/assets/images/svg/robot239_error_logo.svg" class="w-20 h-20" alt="">
            </div>

            <div class="flex flex-col text-2xl">
              <p>Статус:</p>
              <p>не укомплектован</p>
            </div>
          </div>

        </div>

        <vue-qrcode class="!w-[260px] !h-[260px] my-4 ml-[55px]"
                    :value=itemData?.qr
        />

        <takeout-layout takeout="left">
          <button-component >
            <template v-slot:icon-left>
              <img src="@/assets/images/svg/printQR.svg" class="w-full h-full" alt="">
            </template>
            Распечатать QR
          </button-component>
        </takeout-layout>

      </div>
      <item-card-component image="motion-sensor.png" :itemInfo="itemData" v-if="itemData"/>
    </div>
  </div>
</template>
<script>

import TitlePageComponent from "@/components/ui/TitlePageComponent.vue";
import ItemCardComponent from "@/modules/item/ItemCardComponent.vue";
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
import VueQrcode from 'vue-qrcode'

export default {
  name: "Item",

  components: {
    TakeoutLayout,
    ItemCardComponent,
    ButtonComponent,
    TitlePageComponent,
    VueQrcode
  },
  data() {
    return {
      isHistoryOpen: false,
      isGiveOpen: false,
    }
  },
  methods: {
    switchHistoryModal() {
      this.isHistoryOpen = !this.isHistoryOpen
    },
    switchGiveModal() {
      this.isGiveOpen = !this.isGiveOpen
    },
  },

  created() {
    this.$store.dispatch('item/loadItemInfo')
  },
  computed: {
    itemData() {
      return this.$store.state.item.itemData
    }
  },
};
</script>

<style lang="scss" scoped>
.title-span {
  font-weight: 700;
  color: #FFCE38;
}

.lk {
  &__user-info {
    justify-content: space-between;
    margin: 50px 0;
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: center;
      gap: 60px;
    }
  }

  &__other-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 260px;
    @media(max-width: 768px) {
      width: auto;
    }
  }

  &__qr {
    width: 342px;
    height: 342px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: 768px) {
      width: auto;
      height: auto;
    }

    img {
      width: 342px;
      height: 342px;
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
  }

  &__other-info-list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    li {
      font-size: 18px;
      font-weight: 800;
      line-height: 25px;
      text-align: right;

      span {
        color: #FFCE38;
        margin: 0 10px;
      }
    }
  }
}

.table-staff {
  margin: 50px 0;

  table {
    margin: 0 auto;
  }

  td {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;

    color: #343C44;
  }

  th {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;

    color: #6C757D;
  }

  td, th {
    border: 1px solid #CED4DA;
    padding: 10px;
  }

  @media(max-width: 1024px) {
    display: none;
  }

  &_mobile {
    display: none;
    @media(max-width: 1024px) {
      display: block;
    }
  }
}
</style>