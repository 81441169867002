import axios from 'axios'

export default {
  getMenuTree: function() {
    return axios.get(`/account/menu`);
  },
  getNotifications: function(page) {
    const limit = 16;
    return axios.get(`/chat/notifications?limit=${limit}&offset=${limit * page}`);
  },
  getChatUsers: function() {
    return axios.get(`/Chat/Abonents`)
  },
  getGrantHistory: function(id) {
    return axios.get(`/Main/Kit/GrantHistory/${id}`)
  },
  grantKit: function(params) {
    return axios.post('/Main/Kit/Grant', params)
  },
  giveInKit: function(params) {
      return axios.post('/Main/Kit/CompleteGrant', params)
  },
  getKitInfo: function(id) {
    return axios.get(`/Main/Kit/${id}`);
  },
  getItemInfo: function(id) {
    return axios.get(`/Main/Item/${id}`);
  },
  getQRPrintOne: function(id, mode) {
    let url
    switch (mode) {
        case 'user': url = `/User/${id}/PrintQr`;
        break;
        case 'kit': url = `/Main/Kit/${id}/PrintQr`;
        break;
        case 'parts': url = `/Main/Item/${id}/PrintQr`;
    }
    return url;
  },
  getQRPrintList: function(mode) {
    let url
    switch (mode) {
        case 'user': url = `/user/printmultipleqr`;
        break;
        case 'kit': url = `/Main/Kit/printmultipleqr`;
        break;
        case 'parts': url = `/Main/Item/printmultipleqr`;
    }
    return url;
  },
  getKitGrantHistory: function(params) {
      return axios.post('/Main/Kit/GrantHistory', params)
  },
  addItemsToKit(id,items) {
      return axios.post(`/Main/Kit/${id}/FillItems`, items)
  }
}