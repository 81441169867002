<template>
<div class="user-card">
    <div class="user-card__photo">
      <img v-if="user?.image"
           :src="user?.image"
           alt="user"/>
      <img v-else
           src="@/assets/images/avatar.png"
           alt="user"/>
    </div>
  <div class="user-card__content">
    <div class="user-card__name" :class="{'loading-bar': loading}">
      <div class="user-card__lastname" v-if="!loading"> {{ user?.surname || '' }} </div>
      <div class="user-card__firstname" v-if="!loading"> {{ (user?.name || '') + "&nbsp;" + (user?.patronymic || '') }} </div>
    </div>
    <ul class="user-card__list">
      <li class="user-card__item">
        <p class="user-card__key"> Почта </p>
        <p class="user-card__value" :class="{'loading-bar': loading}"> <span v-if="!loading"> {{ user?.email || '-'}} </span> </p>
      </li>
      <li class="user-card__item">
        <p class="user-card__key"> Номер телефона </p>
        <p class="user-card__value" :class="{'loading-bar': loading}"> <span v-if="!loading"> {{ user?.phoneNumber || '-'}} </span> </p>
      </li>
      <li class="user-card__item">
        <p class="user-card__key"> Класс </p>
        <p class="user-card__value" :class="{'loading-bar': loading}"> <span v-if="!loading"> {{ user?.grade || '-' }} </span> </p>
      </li>
      <li class="user-card__item">
        <p class="user-card__key"> Группа </p>
        <p class="user-card__value" :class="{'loading-bar': loading}"> <span v-if="!loading"> {{ user?.workshops  || '-' }} </span> </p>
      </li>
      <li class="user-card__item">
        <p class="user-card__key"> Роль </p>
        <p class="user-card__value" :class="{'loading-bar': loading}"> <span v-if="!loading"> {{ usergroup }} </span> </p>
      </li>
      <li class="user-card__item">
        <p class="user-card__key"> Дополнительная информация </p>
        <p class="user-card__value" :class="{'loading-bar': loading}"> <span v-if="!loading"> {{ user?.comment || '-' }} </span> </p>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
import utils from "@/common/utils"
export default {
  name: "userCardComponent",
  props: {
    user: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {

    }
  },
  computed: {
    loading() {
      return this.$store.state.auth.loadingAccountCurrent
    },
    usergroup() {
      return utils.getGroupTitle(this.user?.userGroup)
    }
  },
}
</script>

<style lang="scss" scoped>
.user-card {
  max-width: 785px;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #FFCE38;
  border-radius: 30px;
  border-bottom-left-radius: 70px;
  margin-left: 30px;
  &__photo {
    margin-left: -30px;
    margin-top: -30px;

    width: 175px;
    height: 175px;
    min-width: 175px;
    min-height: 175px;
    border: 10px #FFCE38 solid;
    box-shadow: #e89b43 0 0 5px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    @media(max-width: 1024px) {
      width: 125px;
      height: 125px;
      min-width: 125px;
      min-height: 125px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 40px 20px;
    @media(max-width: 1024px) {
      margin: 20px 10px 30px 20px;
    }
  }
  &__name {
    display: flex;
    flex-direction: column;
  }
  &__lastname {
    font-size: 32px;
    font-weight: 900;
    line-height: 44px;
    text-align: left;
    color: white;

    text-transform: uppercase;
    @media(max-width: 1024px) {
      font-size: 24px;
      font-weight: 900;
      line-height: 33px;
    }
  }
  &__firstname {
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    color: #4F4F4F;
    @media(max-width: 1024px) {
      font-size: 24px;
      font-weight: 600;
      line-height: 33px;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media(max-width: 1024px) {
      margin-left: -70px;
    }
  }
  &__item {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }
  &__key {
    font-size: 18px;
    font-weight: 800;
    line-height: 25px;
    color: white;
    @media(max-width: 1024px) {
      font-size: 12px;
      flex: 1;
    }
  }
  &__value {
    font-size: 18px;
    font-weight: 800;
    line-height: 25px;
    color: #4F4F4F;
    @media(max-width: 1024px) {
      font-size: 12px;
      flex: 1;
    }
  }
}
.lazy-loading-block {
  position: relative;
}

.loading {
  opacity: 0.5; /* Прозрачность блока во время загрузки */
  pointer-events: none; /* Отключение интерактивности во время загрузки */
}

.loading-animation {
  position: relative;
  height: 30px;
  overflow: hidden;
}

.loading-bar {
  min-width: 100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, #ffffff 50%, transparent 100%);
  background-size: 200% 100%;
  animation: loading 2s infinite;
  border-radius: 15px;
}

@keyframes loading {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
</style>