import registerParts from "@/modules/registers/views/RegisterParts.vue";
import registerkit from "@/modules/registers/views/RegisterKit.vue";
import registerUsers from "@/modules/registers/views/RegisterUsers.vue";
import createUser from "@/modules/registers/views/CreateUser.vue";

export const registerRoutes = [
    {
        path: "/registerParts",
        name: 'register-parts',
        component: registerParts,
        meta: { isPublic: false }
    },
    {
        path: "/registerKit",
        component: registerkit,
        meta: { isPublic: false }
    },
    {
        path: "/registerUsers",
        component: registerUsers,
        meta: { isPublic: false }
    },
    {
        path: "/createUser",
        component: createUser,
        meta: { isPublic: false }
    },
]

