<template>
  <div class="lk container-page">
    <title-page-component :rout-back-view="false">
      <template v-slot:title>
        Личный <span class="title-span">кабинет</span>
      </template>
    </title-page-component>
    <div class="lk__user-info">
      <user-card-component :user="user"/>
      <div class="lk__other-info">
        <div class="lk__qr">
        <vue-qrcode class="!w-[260px] !h-[260px] my-4"
          :value=user.qr
        />
        </div>
        <div class="lk__change-pass">
          <takeout-layout takeout="right">
            <router-link class="router-link" to="/changePass">
              <button-component class="lk__change-pass-button">
                Сменить пароль
              </button-component>
            </router-link>
          </takeout-layout>
        </div>
        <div class="lk__edit-info">
          <takeout-layout takeout="right">
            <button-component @click="onClickPrintQR">
              <template v-slot:icon-left>
                <img src="@/assets/images/svg/printQR.svg" class="w-full h-full" alt="">
              </template>
              Распечатать QR
            </button-component>
          </takeout-layout>
        </div>
      </div>
    </div>
    <div class="lk__edit-info">
      <takeout-layout takeout="left">
        <button-component @click="closeModal()">
          Редактировать
        </button-component>
      </takeout-layout>
    </div>
    <div class="table" v-if="user?.grants?.length">
      <div class="table__title">Перечень оборудования</div>
      <div class="table-staff">
        <table>
          <thead>
          <tr>
            <th>id</th>
            <th>наименование</th>
            <th>тип</th>
            <th>особенности</th>
            <th>дата изменения</th>
            <th>Статус</th>
            <th>Срок до</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in user.grants" :key="item.id">
            <td>{{ item.id || '-' }}</td>
            <td>{{ item.kit.code || '-' }}</td>
            <td>{{ item.kit.kitTypeTitle || '-' }}</td>
            <td> {{ item.kit.feature || '-'}} </td>
            <td> {{ item.grantedDt.toLocaleString() }} </td>
            <td>{{ item.isCompleted === true ? 'Завершен' : 'В работе' }}</td>
            <td> {{ item.deadlineDt.toLocaleString() }} </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table-staff table-staff_mobile">
        <table>
          <tbody v-for="item in user.grants" :key="item.id">
          <tr>
            <td>id</td>
            <td>{{ item.id || '-'  }}</td>
          </tr>
          <tr>
            <td>наименование</td>
            <td>{{ item.kit.code || '-' }}</td>
          </tr>
          <tr>
            <td>тип</td>
            <td>{{ item.kit.kitTypeTitle || '-' || '-'  }}</td>
          </tr>
          <tr>
            <td>особенности</td>
            <td>{{ item.kit.feature || '-' }}</td>
          </tr>
          <tr>
            <td>дата изменения</td>
            <td> {{ item.grantedDt.toLocaleString() }} </td>
          </tr>
          <tr>
            <td>Статус</td>
            <td>{{ item.stateTitle || '-'  }}</td>
          </tr>
          <tr>
            <td>Срок до</td>
            <td> {{ item.deadlineDt.toLocaleString() }} </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <modal-component @close-modal="closeModal" v-if="isEditAccountCurrent">
      <template v-slot:title>Редактирование</template>
      <template v-slot:content>
        <edit-account-current :user="this.user" @close-modal="closeModal()"/>
      </template>
    </modal-component>
  </div>
</template>
<script>

import TitlePageComponent from "@/components/ui/TitlePageComponent.vue";
import UserCardComponent from "@/components/UserCardComponent.vue";
import buttonComponent from "@/components/ui/ButtonComponent.vue";
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
import ModalComponent from "@/components/ui/ModalComponent.vue";
import EditAccountCurrent from "@/components/EditAccountCurrent.vue";
import VueQrcode from 'vue-qrcode'
import api from "@/common/api/api"
import utils from "@/common/utils"

export default {
  name: "Home",
  components: {
    EditAccountCurrent,
    ModalComponent,
    TakeoutLayout,
    buttonComponent,
    UserCardComponent,
    TitlePageComponent,
    VueQrcode
  },

  data() {
    return {
      isEditAccountCurrent: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.accountCurrent
    },
  },
  mounted() {
   this.$store.dispatch('auth/getAccountCurrent')
  },
  methods: {
    onClickPrintQR() {
        let url = api.getQRPrintOne(this.user.id, 'user')
        utils.downloadAndPrintJson(url, null)
      },       
    closeModal() {
      this.isEditAccountCurrent = !this.isEditAccountCurrent
    }
  },
};
</script>

<style lang="scss" scoped>
.router-link {
  width: 100%;
}
.title-span {
  font-weight: 700;
  color: #FFCE38;
}
.lk {
  &__user-info {
    justify-content: space-between;
    margin: 50px 0;
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: center;
      gap: 60px;
    }
  }

  &__other-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 260px;
    align-items: center;
    @media(max-width: 768px) {
      width: auto;
    }
  }

  &__qr {
    width: 300px;
    height: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: 768px) {
    }

    img {
      width: 300px;
      height: 300px;
      @media (max-width: 768px) {
      }
    }
  }

  &__change-pass {
    margin-top: 20px;
    width: 100%;
  }
  &__edit-info {
    width: 100%;
  }

  &__other-info-list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    li {
      font-size: 18px;
      font-weight: 800;
      line-height: 25px;
      text-align: right;

      span {
        color: #FFCE38;
        margin: 0 10px;
      }
    }
  }
}

.table {
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    @media(max-width: 1024px) {
      font-size: 24px;
    }
  }
}

.table-staff {
  margin: 10px 0;

  table {
    margin: 0 auto;
  }

  td {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;

    color: #343C44;
  }

  th {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;

    color: #6C757D;
  }

  td, th {
    border: 1px solid #CED4DA;
    padding: 10px;
  }

  @media(max-width: 1024px) {
    display: none;
  }

  &_mobile {
    display: none;
    @media(max-width: 1024px) {
      display: block;
    }
  }
}
</style>