import { createRouter, createWebHashHistory } from "vue-router";

import About from "@/views/About.vue";
import Kit from "@/views/Kit.vue";
import Chat from "@/views/Chat.vue";
import Report from "@/views/Report.vue";
import Item from "@/views/ItemComponent.vue";

import {
  routes as authRoutes,
  registerGuards as registerAuthGuards,
} from "@/modules/auth/router/index";

import { registerRoutes } from "@/modules/registers/router";
import Lk from "@/views/Lk.vue";
import UserCard from "@/views/UserCard.vue";

const routes = [
  {
    path: "/user/:id",
    component: UserCard,
    meta: { isPublic: false },
  },
  {
    path: "/",
    component: Lk,
    meta: { isPublic: false },
  },
  {},
  {
    path: "/about",
    component: About,
    meta: { isPublic: true },
  },
  {
    path: "/kit/:id",
    component: Kit,
    meta: { isPublic: true },
  },
  {
    path: "/chat",
    component: Chat,
    meta: { isPublic: true },
  },
  {
    path: "/report",
    component: Report,
    meta: { isPublic: true },
  },
  {
    path: "/item/:id",
    component: Item,
    meta: { isPublic: true },
  },
  ...authRoutes,
  ...registerRoutes,
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo(0, 0);
    }
  },
});

registerAuthGuards(router);

export default router;
