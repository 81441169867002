<template>
<div class="user-card">
    <div class="user-card__photo">
     <img  :src="require(`@/assets/images/${this.image}`)" alt="kit"/>
    </div>
  <div class="user-card__content">
    <ul class="user-card__list">
      <li class="user-card__item child:flex-1">
        <p class="user-card__key"> ID </p>
        <p class="user-card__value truncate"> {{ kitInfo.code || '-' }} </p>
      </li>
      <li class="user-card__item child:flex-1">
        <p class="user-card__key flex-1"> Наименование набора </p>
        <p class="user-card__value flex-1 truncate"> {{ kitInfo.shortName || '-' }} </p>
      </li>
      <li class="user-card__item child:flex-1">
        <p class="user-card__key"> Особенности </p>
        <p class="user-card__value truncate"> {{ kitInfo.feature || '-' }} </p>
      </li>
      <li class="user-card__item child:flex-1">
        <p class="user-card__key"> Привязка к кабинету </p>
        <p class="user-card__value truncate"> {{ kitInfo.classroomTitle || '-' }} </p>
      </li>
      <li class="user-card__item child:flex-1">
        <p class="user-card__key"> Пользователь </p>
        <p class="user-card__value truncate"> {{ kitInfo?.grant?.grantedTo || '-' }} </p>
      </li>
      <li class="user-card__item child:flex-1">
        <p class="user-card__key"> Дата выдачи </p>
        <p class="user-card__value truncate"> {{ grantedDate || '-' }} </p>
      </li>
      <li class="user-card__item child:flex-1">
        <p class="user-card__key"> Статус </p>
        <p class="user-card__value whitespace-nowrap flex truncate gap-1"
        :class="[kitInfo.stateId === 1 ? '!text-green-500' : '!text-red-500']">
          <img class=""
          :src="require(`@/assets/images/svg/${
          kitInfo.stateId === 1
          ? 'check-green.svg'
          : 'check-red.svg'}`)"
               alt="">
         {{ kitInfo.stateTitle }} </p>
      </li>
<!--      <li class="user-card__item child:flex-1">-->
<!--        <p class="user-card__key"> Ссылка </p>-->
<!--        <p class="user-card__value truncate"> {{ kitInfo.code || '-' }} </p>-->
<!--      </li>-->
<!--      <li class="user-card__item child:flex-1">-->
<!--        <p class="user-card__key"> Комментарий </p>-->
<!--        <p class="user-card__value truncate"> {{ kitInfo.code || '-' }} </p>-->
<!--      </li>-->
    </ul>
  </div>
</div>
</template>

<script>
export default {
  name: "kitCardComponent",
  props: {
    kitInfo: Object,
    grantedDate: {
      type: String,
      default: '-'
    },
    image: {
      type: String,
      default: "kit-bot.png"
    }
  },
  data() {
    return {};
  }
}
</script>

<style lang="scss" scoped>
.user-card {
  min-height: 435px;
  width: 827px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  background-color: #FFCE38;
  border-radius: 30px;
  border-bottom-left-radius: 70px;
  margin: 0 15px;
  &__photo {
    background-color: white;
    width: 210px;
    height: 210px;
    border: 10px #FFCE38 solid;
    border-radius: 50%;
    @media(max-width: 1024px) {
      width: 125px;
      height: 125px;
      min-width: 125px;
      min-height: 125px;
    }
    img {
      width: auto;
      position: absolute;
    height: 95%;
    bottom: 0;
    right: 63%;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    margin: 20px 0 20px 35px;
    width: 70%;
    height: 395px;
    @media(max-width: 1024px) {
      margin: 15px 0 0 10px;
    }
  }
  &__name {
    display: flex;
    flex-direction: column;
  }
  &__lastname {
    font-size: 32px;
    font-weight: 900;
    line-height: 44px;
    text-align: left;
    color: white;

    text-transform: uppercase;
    @media(max-width: 1024px) {
      font-size: 24px;
      font-weight: 900;
      line-height: 33px;
    }
  }
  &__firstname {
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    color: #4F4F4F;
    @media(max-width: 1024px) {
      font-size: 24px;
      font-weight: 600;
      line-height: 33px;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 21px;
    width: 120%;
    margin-left: -115px;
    min-height: 100%;
    @media(max-width: 1024px) {
      margin-left: -80px;
    }
  }
  &__item {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  &__key {
    font-size: 18px;
    text-align: right;
    font-weight: 800;
    line-height: 25px;
    color: white;
    @media(max-width: 1024px) {
      font-size: 12px;
      flex: 1;
    }
  }
  &__value {
    width: 680px;
    font-size: 18px;
    font-weight: 800;
    align-items: flex-start;
    line-height: 25px;
    color: #4F4F4F;
    white-space: nowrap;
    @media(max-width: 1024px) {
      font-size: 12px;
      flex: 1;
    }
  }
}
</style>