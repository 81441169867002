<template>
  <div class="container-page">
    <title-page-component>
      <template v-slot:title>
        Реестр <span class="title-span">наборов</span>
      </template>
      <template v-slot:button>
        <takeout-layout takeout="right">
          <button-component class="upload-button" @click="onClickExport"
            >Выгрузить
            <template #icon-left>
              <img src="@/assets/images/svg/button-unload.svg" alt="" />
            </template>
          </button-component>
        </takeout-layout>
      </template>
    </title-page-component>
    <register-table
      @edit="openModalEdit"
      @clear-filters="clearFilters"
      @create="openModalCreate"
      @delete="deleteKit"
      @grantedKit="openModalGrantedKit"
      @search="searchKit"
      @upload-register="uploadRegister"
      @sort="sort"
      @togo="togo"
      :total-count="totalCount"
      :current-count="currentCount"
      :register="register"
      :register-title="registerTitle"
      :is-register="'kit'"
      :is-loading="isLoading"
    >
      <template v-slot:filters>
        <div class="filters">
          <div class="filters__toggle" @click="filtersToggle">
            <div>{{ !isfiltersToggle ? "Показать" : "Скрыть" }} фильтры</div>
            <img
              :class="{ 'rotate-180': isfiltersToggle }"
              src="@/assets/images/svg/arrow.svg"
              alt=""
            />
          </div>
          <div class="filters__form" v-if="isfiltersToggle">
            <div class="filters__row">
              <div class="filters__selectors">
                <select-component
                  takeout="left"
                  :options="classroom"
                  v-model:selected-option-id="filtersClassroom"
                  label="Кабинет"
                  class="filters__selector max-w-[150px]"
                />
                <select-component
                  takeout="left"
                  :options="users"
                  v-model:selected-option-id="filtersGrantedTo"
                  label="Кому выдан"
                  class="filters__selector max-w-[350px]"
                />
                <takeout-layout takeout="left">
                  <input-field
                    label="Дата выдачи"
                    v-model:value="filtersGrantedDt"
                    type="date"
                    class="filters__selector"
                  />
                </takeout-layout>
                <takeout-layout takeout="left">
                  <input-field
                    label="Срок сдачи"
                    v-model:value="filtersDeadlineDt"
                    type="date"
                    class="filters__selector"
                  />
                </takeout-layout>
              </div>
            </div>
            <div class="filters__row">
              <div class="filters__selectors">
                <select-component
                  takeout="left"
                  :options="stateKit"
                  v-model:selected-option-id="filtersState"
                  label="Статус"
                  class="filters__selector max-w-[150px]"
                />
                <div class="filters__apply">
                  <div class="flex gap-[20px]">
                    <takeout-layout>
                      <button-component
                        class="filters__selector"
                        @click="getKit"
                      >
                        Применить
                      </button-component>
                    </takeout-layout>
                    <takeout-layout>
                      <button-component
                        class="filters__selector"
                        @click="clearFilters"
                      >
                        Сбросить фильтр
                        <template #icon-left>
                          <img
                            src="@/assets/images/svg/button-update.svg"
                            alt=""
                          />
                        </template>
                      </button-component>
                    </takeout-layout>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </register-table>
    <div class="modals">
      <modal-component
        @close-modal="this.isCreatRegister = !this.isCreatRegister"
        v-if="isCreatRegister"
      >
        <template v-slot:title>Добавление набора</template>
        <template v-slot:content>
          <div class="modal-register" @click.stop>
            <takeout-layout>
              <input-field
                class="input-component"
                v-model:value="newRegister.code"
                label="Наименование набора"
                :label-error="validatedCreateForm.code"
                type="text"
                max-length="50"
              />
            </takeout-layout>
            <takeout-layout>
              <input-field
                class="input-component"
                v-model:value="newRegister.feature"
                label="Особенности"
                :label-error="validatedCreateForm.feature"
                type="text"
                max-length="100"
              />
            </takeout-layout>
            <select-component
              class="input-component"
              label="Кабинет"
              :options="classroom"
              v-model:selectedOptionId="newRegister.classroomId"
              :label-error="validatedCreateForm.classroomId"
            />
            <takeout-layout>
              <button-component @click="creatRegister">
                Сохранить
                <template #icon-left>
                  <img src="@/assets/images/svg/button-add.svg" alt="" />
                </template>
              </button-component>
            </takeout-layout>
          </div>
        </template>
      </modal-component>
      <modal-component
        @close-modal="this.isEditRegister = !this.isEditRegister"
        v-if="isEditRegister"
      >
        <template v-slot:title> Редактирование набора </template>
        <template v-slot:content>
          <div class="modal-register" @click.stop>
            <takeout-layout>
              <input-field
                v-model:value="editRegister.code"
                class="input-component"
                label="Id набора"
                :label-error="validatedEditForm.code"
                type="text"
                max-length="50"
              />
            </takeout-layout>
            <takeout-layout>
              <input-field
                v-model:value="editRegister.feature"
                class="input-component"
                label="Особенности"
                type="text"
                max-length="100"
              />
            </takeout-layout>
            <takeout-layout>
              <select-component
                class="input-component"
                label="Кабинет"
                :options="classroom"
                v-model:selectedOption="editSelectClassroom"
              />
            </takeout-layout>
            <select-component
              class="input-component"
              label="Статус"
              :options="stateKitForEdit"
              v-model:selectedOption="editSelectState"
            />
            <takeout-layout>
              <button-component @click="editKit"> Сохранить </button-component>
            </takeout-layout>
          </div>
        </template>
      </modal-component>
      <get-kit-modal
        v-if="isGrantedKit"
        @close-modal="isGrantedKit = false"
        :is-granted-kit-i-d="isGrantedKitID"
      />
    </div>
  </div>
</template>

<script>
import TitlePageComponent from "@/components/ui/TitlePageComponent.vue";
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
import RegisterTable from "@/modules/registers/RegisterTable.vue";
import axios from "axios";
import ModalComponent from "@/components/ui/ModalComponent.vue";
import InputField from "@/components/ui/InputField.vue";
import SelectComponent from "@/components/ui/SelectComponent.vue";
import { handleKitData } from "@/modules/registers/helpers/handle-kit-data";
import utils from "@/common/utils";
import GetKitModal from "@/modules/kit/GetKitModal.vue";
import { checkAllFieldsEmpty, checkFieldEmpty } from "@/helpers/validate";

export default {
  name: "registerKit",
  components: {
    GetKitModal,
    SelectComponent,
    RegisterTable,
    TakeoutLayout,
    ButtonComponent,
    TitlePageComponent,
    ModalComponent,
    InputField,
  },
  data() {
    return {
      isLoading: false,
      register: [],
      registerTitle: [
        {
          code: "code",
          title: "id",
          width: "120px",
        },
        {
          code: "shortName",
          title: "Наименование набора",
          width: "170px",
        },
        {
          code: "feature",
          title: "Особенности",
          width: "170px",
        },
        {
          code: "classroom.title",
          title: "Кабинет",
          width: "105px",
        },
        {
          code: "grant.grantedTo",
          title: "Кому выдан",
          width: "180px",
        },
        {
          code: "grant.grantedDt",
          title: "Дата выдачи",
          width: "115px",
        },
        {
          code: "grant.deadlineDt",
          title: "Срок сдачи",
          width: "115px",
        },
        {
          code: "state",
          title: "Статус",
          width: "150px",
        },
      ],
      searchValue: "",

      pageKit: 1,
      totalCount: null,
      currentCount: null,

      isCreatRegister: false,
      isEditRegister: false,
      isGrantedKit: false,
      isGrantedKitID: null,

      newRegister: {
        code: null,
        kitTypeId: null,
        feature: null,
        classroomId: null,
        shortName: null,
      },
      editRegister: {
        code: "",
        kitTypeId: "",
        stateId: null,
        feature: "",
        classroomId: null,
        shortName: null,
      },
      editSelectClassroom: {},
      editSelectState: {},

      stateKitForEdit: [],

      idEditRegister: "",

      titleSort: "",
      directionSort: null,

      validatedCreateForm: {
        code: "",
        classroomId: "",
      },

      validatedEditForm: {
        code: "",
        shortName: "",
      },

      isfiltersToggle: false,

      filtersClassroom: "",
      filtersGrantedTo: "",
      filtersGrantedDt: "",
      filtersDeadlineDt: "",
      filtersState: "",
    };
  },
  methods: {
    openModalEdit(id) {
      this.clearValidated();
      this.isEditRegister = !this.isEditRegister;
      axios.get("main/kit/" + id).then((res) => {
        const response = res.data;
        this.idEditRegister = response.id;
        this.editRegister.code = response.code;
        this.editRegister.shortName = response.shortName;
        this.editRegister.kitTypeId = response.kitTypeId;
        this.editRegister.feature = response.feature;
        this.editSelectState = this.stateKit.find(
          (item) => item.title === response?.stateTitle,
        );
        this.editSelectClassroom = this.classroom.find(
          (item) => item.title === response.classroomTitle,
        );
        if (!response?.grant?.isCompleted) {
          this.stateKitForEdit = this.stateKit.filter((item) => item.id === 3);
        }
        if (!response.grant || response?.grant?.isCompleted) {
          this.stateKitForEdit = this.stateKit.filter((item) => item.id === 1 || item.id === 2)
        }
      });
    },
    openModalCreate() {
      this.clearValidated();
      this.isCreatRegister = !this.isCreatRegister;
      this.newRegister.classroomId = null;
      this.newRegister.feature = null;
      this.newRegister.code = null;
      this.newRegister.shortName = null;
    },
    openModalGrantedKit(id) {
      this.isGrantedKit = !this.isGrantedKit;
      this.isGrantedKitID = id;
    },
    async getKit() {
      this.isLoading = true;

      this.pageKit = 1;
      try {
        const response = await axios.post(
          "main/kit/search",
          this.requestParams,
        );
        const { totalCount, data, count } = handleKitData(response);
        this.totalCount = totalCount;
        this.register = data;
        this.currentCount = count;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    async subloadKit() {
      this.isLoading = true;
      try {
        const response = await axios.post(
          "main/kit/search",
          this.requestParams,
        );
        const { totalCount, data, count } = handleKitData(response);

        this.totalCount = totalCount;
        this.register.push(...data);
        this.currentCount += count;
        this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    editKit() {
      this.checkedValidatedEditForm();
      if (this.isValidateEditForm) {
        this.editRegister.stateId = this.editSelectState.id;
        this.editRegister.classroomId = this.editSelectClassroom.id;
        axios
          .put("main/kit/" + this.idEditRegister, this.editRegister)
          .then(() => {
            this.pageKit = 1;
            this.getKit();
          });
        this.isEditRegister = false;
        this.clearValidated();
      }
    },
    uploadRegister() {
      this.pageKit += 1;
      this.subloadKit();
    },

    onClickExport() {
      let currentURL = `/Main/Kit/export`;
      utils.downloadFileExport(currentURL, this.requestParams);
    },

    creatRegister() {
      this.checkedValidatedCreateForm();
      if (this.isValidateCreateForm) {
        axios
          .post("main/kit", this.newRegister)
          .then(() => {
            this.getKit();
          })
          .catch((err) => {
            console.log(err);
          });
        this.newRegister.classroomId = null;
        this.newRegister.code = null;
        this.newRegister.feature = null;
        this.newRegister.kitTypeId = null;

        this.isCreatRegister = false;
      }
    },
    checkedValidatedCreateForm() {
      const formCreate = this.newRegister;
      const vc = this.validatedCreateForm;
      vc.code = checkFieldEmpty(formCreate?.code);
      vc.classroomId = checkFieldEmpty(formCreate?.classroomId);
    },
    checkedValidatedEditForm() {
      const formEdit = this.editRegister;
      const ve = this.validatedEditForm;
      ve.code = checkFieldEmpty(formEdit?.code);
    },
    clearValidated() {
      const vc = this.validatedCreateForm;
      const ve = this.validatedEditForm;

      vc.code = "";
      vc.shortName = "";
      vc.classroomId = "";
      ve.code = "";
      ve.shortName = "";
    },
    sort(code) {
      this.titleSort = code;
      this.directionSort === 1
        ? (this.directionSort = 0)
        : (this.directionSort = 1);
      this.getKit();
    },
    searchKit(searchValue) {
      this.titleSort = "";
      this.directionSort = null;
      this.searchValue = searchValue;
      this.getKit();
    },
    async deleteKit(id) {
      await axios.delete("main/kit/" + id).then(() => {
        this.getKit();
      });
    },
    togo(id) {
      this.$router.push("kit/" + id);
    },
    filtersToggle() {
      this.isfiltersToggle = !this.isfiltersToggle;
    },
    clearFilters() {
      this.filtersDeadlineDt = "";
      this.filtersClassroom = "";
      this.filtersGrantedDt = "";
      this.filtersGrantedTo = "";
      this.filtersState = "";
    },
  },
  mounted() {
    this.getKit();
    this.$store.dispatch("handbooks/getTypeKit");
    this.$store.dispatch("handbooks/getStateKit");
    this.$store.dispatch("handbooks/getClassRoom");
    this.$store.dispatch("users/getUsers");

    const currentDate = new Date();
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);

    const day = String(nextDate.getDate()).padStart(2, "0");
    const month = String(nextDate.getMonth() + 1).padStart(2, "0");
    const year = nextDate.getFullYear();

    this.isGrantedDeadlineDt = `${year}-${month}-${day}`;
  },
  computed: {
    isValidateEditForm() {
      return checkAllFieldsEmpty(this.validatedEditForm);
    },
    isValidateCreateForm() {
      return checkAllFieldsEmpty(this.validatedCreateForm);
    },
    typeKit() {
      return this.$store.state.handbooks.typeKit;
    },
    stateKit() {
      return this.$store.state.handbooks.stateKit;
    },
    classroom() {
      return this.$store.state.handbooks.classroom;
    },
    users() {
      return this.$store.getters["users/usersForSelect"];
    },
    requestParams() {
      return {
        page: this.pageKit,
        count: 10,
        search: {
          text: this.searchValue || "",
          fieldNames: [],
          allTextFields: true,
          nonStrictMatch: true,
        },
        sortItems: [
          {
            fieldName: this.titleSort || "id",
            direction: this.directionSort || 0,
          },
        ],
        filterItems: [
          {
            fieldName: "classRoomId",
            condition: 0,
            value: this.filtersClassroom,
          },
          {
            fieldName: "grant.grantedToId",
            condition: 0,
            value: this.filtersGrantedTo,
          },
          {
            fieldName: "grant.grantedDt",
            condition: 0,
            value: this.filtersGrantedDt,
          },
          {
            fieldName: "grant.deadlineDt",
            condition: 0,
            value: this.filtersDeadlineDt,
          },
          {
            fieldName: "stateId",
            condition: 0,
            value: this.filtersState,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-button {
  width: 220px;
}

.title-span {
  font-weight: 700;
  color: #ffce38;
}

.modal-register {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.input-component {
  width: 300px;
}

.filters {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
  &__toggle {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    gap: 15px;
    cursor: pointer;
  }
  &__form {
    display: flex;
    gap: 30px;
    flex-direction: column;
  }
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__apply {
    align-self: flex-end;
    margin-left: auto;
  }
  &__selector {
    width: 100%;
    min-width: 240px;
  }
  &__selectors {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
  }
}
.rotate-180 {
  transform: rotate(180deg);
}
</style>