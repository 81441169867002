<template>
  <div class="table-register">
    <div class="search-wrapper">
      <takeout-layout takeout="left">
        <input-field
          class="input-component"
          @search="eventSearch"
          v-model:value="searchValue"
          :label-error="errorLengthSearch"
          label="Поиск"
          type="search"
        />
      </takeout-layout>
      <takeout-layout class="flex-1" takeout="left">
        <button-component @click="clearSearch"
          >Очистить
          <template #icon-left>
            <img src="@/assets/images/svg/button-update.svg" alt="" />
          </template>
        </button-component>
      </takeout-layout>
      <takeout-layout>
        <button-component @click="onClickPrintQR">
          Распечатать QR
        </button-component>
      </takeout-layout>
      <takeout-layout class="takeout-layout" takeout="right">
        <button-component
          class="button-component new-note"
          @click="this.$emit('create')"
          >Добавить запись
          <template #icon-left>
            <img src="@/assets/images/svg/button-add.svg" alt="" />
          </template>
        </button-component>
      </takeout-layout>
    </div>
    <slot name="filters"> </slot>
    <div class="relative">
      <div class="table-wrapper" ref="scrollContainer" @scroll="handleScroll">
        <table>
          <thead>
            <tr>
              <th class="table__head-index">№</th>
              <th class="table__head-checkbox">
                <checkbox />
              </th>
              <th
                :style="{
                  width: item.width,
                  'min-width': item.width,
                  'max-width': item.width,
                }"
                v-for="item in registerTitle"
                :key="item.index"
              >
                <div
                  @click="sort(item)"
                  class="table__head-item"
                  :class="{ fio: item.title === 'fullName' }"
                >
                  <p>{{ item.title }}</p>
                  <img
                    class="sort-img"
                    :class="{
                      'rotate-180':
                        item === sortBy.title && sortBy.direction === 0,
                      opacity: item === sortBy.title,
                    }"
                    src="@/assets/images/svg/table-register-icon-pyramid.svg"
                    alt=""
                  />
                </div>
              </th>
              <th>
                <div class="table__head-item"></div>
              </th>
            </tr>
          </thead>
          <tbody class="tbody" :class="{ blur: isLoading }">
            <tr
              @click="selectedKit(item)"
              class="table__row"
              :class="[
                {
                  disabled:
                    (item.data.state === 'Утеряна' ||
                     item.data.state === 'В наборе') &&
                     isAddPartMode,
                },
                { 'selected-kit': selectKit?.includes(item.id) },
                {
                  'deadline-back':
                    (item?.data?.deadlineDt
                      ? item?.data?.deadlineDt <= this.currentDate
                      : false) || item?.data?.isDeleted === 'Удален',
                },
              ]"
              v-for="(item, index) in register"
              :key="item.id"
            >
              <td>
                {{ index + 1 }}
              </td>
              <td>
                <checkbox
                  @change="selectedKit(item)"
                  :is-checked="!!selectKit?.includes(item.id)"
                />
              </td>
              <td
                :class="{
                  'not-staffed':
                    value === 'Не укомплектован' ||
                    value === 'Утеряна' ||
                    value === 'Удален',
                }"
                v-for="value in item.data"
              >
                <span class="table__cell">
                  <router-link
                      v-if="item.data?.code === value && value"
                      class="table__link"
                      :to="linkIsRegister + item.id"
                  >{{ item.data?.code || "-" }}</router-link
                  >
                  <router-link
                    v-else-if="item.data?.kitType === value && value"
                    class="table__link"
                    :to="'/kit/' + item.kitId"
                  >
                    {{ value || "-" }}
                  </router-link>
                  <router-link
                      v-else-if="item.data?.grantedTo === value && value"
                      class="table__link"
                      :to="'/user/' + item?.grantedToId"
                  >
                    {{ value || "-" }}
                  </router-link>
                  <span v-else>{{ value || "-" }}</span>
                </span>
              </td>
              <td>
                <div class="icon-registers">
                  <img
                    title="Редактировать"
                    @click.stop="edit(item.id)"
                    class="icon-registers__item"
                    src="@/assets/images/svg/edit-icon.svg"
                    alt=""
                  />
                  <img
                    :title="[
                      this.isRegister === 'kit'
                        ? 'Перейти к карточке набора'
                        : 'Перейти к карточке детали',
                    ]"
                    v-if="
                      this.isRegister === 'kit' || this.isRegister === 'parts'
                    "
                    @click="togokit(item.id)"
                    class="icon-registers__item"
                    src="@/assets/images/svg/togo-icon.svg"
                    alt=""
                  />
                  <img
                    title="Выдать набор"
                    v-if="this.isRegister === 'kit'"
                    @click="grantedKit(item.id)"
                    class="icon-registers__item"
                    src="@/assets/images/svg/granted-icon.svg"
                    alt=""
                  />
                  <img
                    title="Распечатать QR-код"
                    @click="printQr(item.id)"
                    class="icon-registers__item"
                    src="@/assets/images/qr.png"
                    alt=""
                  />
                  <img
                    title="Удалить"
                    @click="deleteKit(item.id)"
                    class="icon-registers__item"
                    src="@/assets/images/svg/delete-icon.svg"
                    alt=""
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="spinner" v-if="isLoading"></div>
      <div class="error-length" v-if="errorSearch">Нет данных!</div>
    </div>
  </div>
</template>
<script>
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import InputField from "@/components/ui/InputField.vue";
import Checkbox from "@/components/ui/Checkbox.vue";
import utils from "@/common/utils";
import api from "@/common/api/api";

export default {
  name: "registerTable",
  components: {
    Checkbox,
    InputField,
    ButtonComponent,
    TakeoutLayout,
  },
  props: {
    register: Array,
    registerTitle: Array,
    totalCount: Number,
    currentCount: Number,

    isRegister: String,

    isLoading: Boolean,

    errorSearch: { type: Boolean, default: false },
  },
  data() {
    return {
      sortBy: {
        title: "",
        direction: null,
      },
      searchValue: "",
      selectKit: [],
      isDeletedKit: null,
      errorLengthSearch: '',
      currentDate: "",
      isChecked: false,
      isAddPartMode: false,

      tableStartY: null,
    };
  },
  mounted() {
    this.getCurrentDate();
    if (localStorage.getItem('selectKit') && JSON.parse(localStorage.getItem('selectKit')).mode === 'partsSelect') {
      this.isAddPartMode = true
    }
    const table = this.$refs.scrollContainer
    const rect = table.getBoundingClientRect();
    this.tableStartY = rect.top + window.scrollY;
    table.style.maxHeight = `calc(100vh - ${this.tableStartY + 10}px)`

  },
  watch: {
    register: {
      handler() {
        this.handleScroll();
      },
      flush: "post",
    },
  },

  computed: {
    linkIsRegister() {
      if (this.isRegister === "parts") {
        return "/item/";
      } else if (this.isRegister === "kit") {
        return "/kit/";
      } else if (this.isRegister === "user") {
        return "/user/";
      } else {
        return null;
      }
    },
  },

  methods: {
    eventSearch() {
      if (this.searchValue.length > 2) {
        this.$emit("search", this.searchValue);
        this.errorLengthSearch = '';
      } else {
        this.errorLengthSearch = 'Введите более 2-х символов';
      }
    },

    clearSearch() {
      this.searchValue = "";

      this.$emit("clearSearch");
      this.$emit("search", this.searchValue);
    },
    clearFilters() {
      this.sortBy.direction = null;
      this.sortBy.title = "";
      this.$emit('clearFilters')
    },
    sort(item) {
      if (item === this.sortBy.title) {
        this.sortBy.direction === 0
          ? (this.sortBy.direction = 1)
          : (this.sortBy.direction = 0);
      } else {
        this.sortBy.title = item;
      }
      this.$emit("sort", item.code);
    },
    async deleteKit(id) {
      if (id) {
        this.$emit("delete", id);
        this.clearSelectedKit();
      }
    },
    togokit(id) {
      this.$emit("togo", id);
    },
    grantedKit(id) {
      this.$emit("grantedKit", id);
    },
    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (
        container?.scrollTop + container?.clientHeight >=
          container?.scrollHeight - 2 &&
        this.currentCount < this.totalCount
      ) {
        this.$emit("uploadRegister");
      }
    },
    selectedKit(item) {
      const index = this.selectKit?.indexOf(item.id);
      if (index !== -1) {
        this.selectKit?.splice(index, 1);
      } else {
        this.selectKit.push(item.id);
      }
      this.$emit("selectedItems", this.selectKit);
    },
    clearSelectedKit() {
      this.selectKit = null;
    },
    edit(id) {
      this.$emit("edit", id);
      this.selectKit = [];
    },
    getCurrentDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      this.currentDate = `${day}-${month}-${year}`;
    },
    onClickPrintQR() {
      let url = api.getQRPrintList(this.isRegister);
      if (url)
        utils.downloadAndPrintJson(url, { ids: this.selectKit.join(",") });
    },
    printQr(id) {
      let url = api.getQRPrintOne(id, this.isRegister);
      if (url) utils.downloadAndPrintJson(url, null);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.disabled {
  pointer-events: none;
  background: rgba(236, 236, 236, 0.9) !important;
  opacity: 0.8;
}
.table-wrapper {
  position: relative;
  border: 1px solid rgba(87, 87, 87, 0.38);
  margin-top: 30px;
  min-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  background: white;
}

.edit-buttons {
  display: flex;
  flex-direction: row;
  gap: 30px;

  .takeout-layout:nth-child(2) {
    flex: 1;
  }
}
a:hover {
  color: #bb972a;
}
.table {
  &__link {
    font-weight: 700;
    text-decoration: underline;
  }

  &__row {
    cursor: pointer;
    &:hover,
    &:active {
    }
  }
  &__head-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__head-index {
    width: 40px;
    max-width: 40px;
    min-width: 40px;
  }
  &__head-checkbox {
    width: 34px;
    max-width: 34px;
    min-width: 34px;
  }
  &__cell {
    font-size: 16px;
  }
}

.table__row .not-staffed span {
  color: #ff0000;
  font-weight: 700;
}

.table-register {
  margin: 10px 0;

  table {
    margin: 0 auto;
    width: 100%;
    border-collapse: separate; /* Разделяем границы ячеек */
    border-spacing: 0.1px; /* Устанавливаем отступ между ячейками в 10px */
  }
  thead {
    z-index: 4;
    position: sticky;
    top: -1px;
  }
  tbody {
    tr:nth-child(even) {
      background: #f9f9f9;
    }
    tr:nth-child(odd) {
      background: white;
    }
  }

  td {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    color: #343c44;
    -webkit-hyphens: auto;
    hyphens: auto;
    word-wrap: break-word;
    word-break: break-word;
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }

  th {
    cursor: pointer;
    background-color: #ffce38;
    font-size: 14px;
    font-weight: 700;
    line-height: 25px;
    color: #6c757d;
    overflow: hidden;
  }

  td,
  th {
    border: 1px solid rgba(87, 87, 87, 0.38);
    border-top: none;
    padding: 10px 10px;
  }

  @media (max-width: 1024px) {
  }

  &_mobile {
    display: none;
    @media (max-width: 1024px) {
      display: block;
    }
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.error-length {
  margin: 50px auto;
  text-align: center;
  font-size: 22px;
  color: red;
}
.icon-registers {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 5px;
  &__item {
    width: 18px;
    min-width: 18px;
    height: 18px;
  }
}
.sort-img {
  margin: 10px;
  opacity: 0;
}
.table-register .table-wrapper .deadline-back {
  background: #ff00001a;
}
.blur {
  filter: blur(4px);
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 6px solid rgba(255, 255, 255, 0.3);
  border-top: 6px solid #ffce38;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.table-register .table-wrapper table .selected-kit {
  background-color: rgba(255, 206, 56, 0.39);
}
.fio {
  min-width: 500px;
}
.opacity {
  opacity: 1;
}
</style>
