<template>
  <div class="button-wrapper" :style="[`max-width: ${maxWidth}px; width: ${width}px`, isCentered ? 'margin: auto' : '']">
    <div class="error-label">
      <slot name="error-label"></slot>
    </div>
    <button :disabled="isDisabled" :class="[ !isDisabled ? 'button' : 'button button-disabled']">
      <span class="button__icon"><slot name="icon-left"> </slot></span>
      <slot></slot>
      <span class="button__icon"><slot name="icon-right"></slot></span>
    </button>
  </div>
</template>

<script>
export default {
  name: "buttonComponent",
  props: {
    isSubmitting: Boolean,
    icon: String,
    errorLabel: String,
    maxWidth: {
      type: String,
      default: "300"
    },
    width: {
      type: String,
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isCentered: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="scss" scoped>
.button-wrapper {
  width: 100%;
  position: relative;
  box-shadow: none;

  button {
    box-shadow: none;
  }
  .button {
    font-size: 16px;
    font-weight: 800;
    line-height: 22px;

    padding: 10px 30px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    background: #FFCE38;
    border-radius: 40px;
    border: none;
    height: 48px;
    box-shadow: none;
  }
  .button-disabled {
    background-color: #c4c3c3;
  }
}
.error-label {
  position: absolute;
  top: -22px;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  color: red;
}
</style>