<template>
  <div class="burger-wrapper" :class="{active:isActive}">
    <burger-menu class="burger__button" :is-active="isActive" @click="toggle"/>
    <transition name="fade">
      <div v-if="isActive" class="menu">
        <div class="menu__body">
          <ul class="menu_list">
            <menu-item-component @select-item="isActive = !isActive" v-for="item in items" :key="item.id" :item="item"/>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BurgerMenu from "@/components/header/BurgerMenu.vue";
import api from "@/common/api/api"
import refreshAuthorizationHeader from "../../common/axios";
import menuItemComponent from "./MenuItemComponent.vue";

export default {
  name: "menuComponent",
  components: {
    BurgerMenu,
    menuItemComponent
  },
  data() {
    return {
      isActive: false,
      items: [],
    };
  },
  created() {
    refreshAuthorizationHeader();
    this.loadMenu();
  },
  methods: {
    loadMenu() {
      api.getMenuTree().then((res) => {
        this.items = res.data;
        this.setExpanded(this.items);
      });
    },
    toggle() {
      this.isActive = !this.isActive
      this.$emit('isActiveBurgerMenu')
      document.body.classList.add('active-left-menu')
    },
    setExpanded(items) {
      if (items)
        items.forEach(x => {
          this.setExpanded(x.items);
          x.expanded = x.items.some(it => it.url?.trim() == this.$route.path.trim() || it.expanded);
        });
    },
    getIsExpanded(item) {
      let isExpanded = item.items.some(it => it.url?.trim() == this.$route.path.trim());
      if (!isExpanded && item.items.length > 0)
        isExpanded = item.items.some(it => this.getIsExpanded(it));
      return isExpanded
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/theme/default/colors.scss";

.burger__button {
  align-self: end;
  border: none;
  z-index: 10;
}

.active {
  display: flex;
  flex-direction: column;
}

.switch {
  margin-top: 32px;
}

.menu__btns {
  position: relative;
  margin-top: 45px;
  margin-left: 25px;
}

.menu_list {
  p,
  a,
  span {
    margin: 0;
    text-decoration: none;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    color: $whiteMist;
  }

  p {
    display: block;
  }

  a {
    display: block;
  }

  span {
    display: inline-block;
  }
}
.menu__item {
  cursor: pointer;

  .menu__sub-items {
    padding-left: 32px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .collapse-icon {
    position: relative;
    min-width: 18px;
    width: 18px;
    height: 18px;

    &::after {
      position: absolute;
      content: '';
      right: calc(50% - 4px);
      bottom: calc(50% - 4px);
      width: 8px;
      height: 8px;
      border-right: 4px solid $whiteMist;
      border-bottom: 4px solid $whiteMist;
      border-left: 4px solid transparent;
      border-top: 4px solid transparent;
      transform-origin: center;
      transform: rotate(-45deg);
      transition-property: transform;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
    }

    &_expanded::after {
      transform: rotate(45deg) translate(-1px, -1px);
    }
  }

  .menu__root-item {
    display: flex;
    &-link {
      margin-left: 18px;
    }
  }

  .active-link {
    color: #ACDAE8;
  }
}

.menu {

  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;

  border: 10px solid #4F4F4F;
  border-top: none;
  top: 0;
  right: -30px;
  margin-top: 90px;
  background-color: #FFCE38;

  position: absolute;
  min-width: 360px;
  padding: 110px 22px 17px 22px;

  @media(max-width: 768px) {
    border: none;
    border-radius: 0;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-top: 0;
    padding: 60px 22px 17px 70px;
  }
  &::after {
    @media(max-width: 768px) {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 82%;
      width: 55px;
      margin: 55px 0;
      background: white;
      border-top-left-radius: 99px;
      border-bottom-left-radius: 99px;
      transform: matrix(3.0, 0.3, 0, 1.2, 25, 25);
    }
  }
  &_list {
    margin-top: -100px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media(max-width: 768px) {
      max-width: 200px;
      margin-top: 0;
      padding-top: 90px;
    }
  }
}

@media(max-width: 320px) {
  .btn {
    left: 5px;
    width: 60px;
  }
}

</style>
