<template>
  <div class="user-card">
    <div class="user-card__photo">
      <img  :src="require(`@/assets/images/${this.image}`)" alt="item"/>
    </div>
    <div class="user-card__content">
      <ul class="user-card__list">
        <li class="user-card__item child:flex-1">
          <p class="user-card__key"> ID </p>
          <p class="user-card__value truncate"> {{ itemInfo?.id || "-" }} </p>
        </li>
        <li class="user-card__item child:flex-1">
          <p class="user-card__key flex-1"> Наименование </p>
          <p class="user-card__value flex-1 truncate"> {{ itemInfo?.code || "-" }}  </p>
        </li>
        <li class="user-card__item child:flex-1">
          <p class="user-card__key"> Тип </p>
          <p class="user-card__value truncate"> {{ itemInfo?.itemTypeTitle || "-" }} </p>
        </li>
        <li class="user-card__item child:flex-1">
          <p class="user-card__key"> Набор </p>
          <p class="user-card__value truncate"> {{ itemInfo.kit?.kitTypeTitle || "-" }} </p>
        </li>
        <li class="user-card__item child:flex-1">
          <p class="user-card__key"> Особенности </p>
          <p class="user-card__value truncate"> {{ itemInfo?.comment || "-" }} </p>
        </li>
        <li class="user-card__item child:flex-1">
          <p class="user-card__key"> Статус </p>
          <p class="user-card__value truncate"> {{ itemInfo.stateTitle || "-" }} </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemCardComponent",
  props: {
    itemInfo: Object,
    image: {
      type: String,
      default: "kit-bot.png"
    }
  },
  data() {
    return {};
  }
}
</script>

<style lang="scss" scoped>
.user-card {
  height: 302px;
  width: 819px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  background-color: #FFCE38;
  border-radius: 30px 30px 30px 70px;
  margin: 30px 15px 0 15px;
  &__photo {
    background-color: white;
    width: 245px;
    height: 245px;
    border: 10px #FFCE38 solid;
    border-radius: 50%;
    @media(max-width: 1024px) {
      width: 125px;
      height: 125px;
      min-width: 125px;
      min-height: 125px;
    }
    img {
      width: auto;
      position: absolute;
      height: 100%;
      bottom: 0;
      right: 73%;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    padding-right: 180px;
    margin: 20px 0;
    width: 64%;
    height: 265px;
    @media(max-width: 1024px) {
      margin: 15px 0 0 10px;
    }
  }
  &__name {
    display: flex;
    flex-direction: column;
  }
  &__lastname {
    font-size: 32px;
    font-weight: 900;
    line-height: 44px;
    text-align: left;
    color: white;

    text-transform: uppercase;
    @media(max-width: 1024px) {
      font-size: 24px;
      font-weight: 900;
      line-height: 33px;
    }
  }
  &__firstname {
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    color: #4F4F4F;
    @media(max-width: 1024px) {
      font-size: 24px;
      font-weight: 600;
      line-height: 33px;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 21px;
    width: 100%;
    min-height: 100%;
    @media(max-width: 1024px) {
      margin-left: -80px;
    }
  }
  &__item {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  &__key {
    font-size: 18px;
    text-align: right;
    font-weight: 800;
    line-height: 25px;
    color: white;
    @media(max-width: 1024px) {
      font-size: 12px;
      flex: 1;
    }
  }
  &__value {
    //max-
    width: 665px;
    font-size: 18px;
    font-weight: 800;
    line-height: 25px;
    color: #4F4F4F;
    white-space: nowrap;
    @media(max-width: 1024px) {
      font-size: 12px;
      flex: 1;
    }
  }
}
</style>