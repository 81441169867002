<template>
  <takeout-layout :z-index="isDropdownOpen" :takeout="takeout">
    <div
      ref="select"
      class="select-wrapper"
      :class="{ 'z-index': isDropdownOpen }"
    >
      <div class="select" ref="select">
        <label
          v-if="
            !selectedOption && !selectedOptionId && !selectedOptions?.length >= 1
          "
          class="select__label"
          >{{ label }}</label
        >
        <label
          v-if="
            !this.selectedOption &&
            !this.optionTitle &&
            !this.selectedOptions?.length >= 1
          "
          class="select__label select__label_error"
          >{{ labelError }}</label
        >

        <div class="select__container takeout-right">
          <div class="select__dropdown" @click="toggleDropdown" v-on-click-outside="hide">
            <div class="select__selected-content">
              <span v-if="!isMultiSelect">{{
                (selectedOptionId ? optionTitle : false) || selectedOption?.title
              }}</span>
              <span v-else>
                <span>{{ selectedOptions[0]?.title }} </span>
                <span v-if="selectedOptions.length >= 2">...</span>
              </span>
            </div>
            <img
              class="select__arrow"
              :class="{ 'select__arrow--open': isDropdownOpen }"
              src="@/assets/images/svg/arrow.svg"
              alt=""
            />
          </div>
          <div v-show="isDropdownOpen" class="select__options">
            <ul class="select__options-list">
              <li
                v-for="(option, index) in options"
                :key="index"
                @click="selectOption(option)"
              >
                <span v-if="!isMultiSelect">{{ option.title }}</span>
                <span v-else>
                  <input
                    type="checkbox"
                    :value="option"
                    v-model="selectedOptions"
                  />
                  {{ option.title }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </takeout-layout>
</template>

<script>
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
import { vOnClickOutside } from '@vueuse/components'
export default {
  directives: {
    'on-click-outside': vOnClickOutside,
  },
  name: "selectComponent",
  components: { TakeoutLayout },
  data() {
    return {
      optionTitle: null,
      selectedOptions: [],
      isDropdownOpen: false,
      selectId: "",
    };
  },
  props: {
    selectedOption: [Object, Array],
    selectedOptionId: [Number, Array],
    selectedOptionTitle: [String, Array],
    label: String,
    selectedId: Number,
    labelError: String,
    options: Array,
    isMultiSelect: {
      type: Boolean,
      default: false,
    },
    takeout: {
      type: String,
      default: "right",
    },
  },
  methods: {
    hide() {
      this.isDropdownOpen = false
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      this.$emit("isDropdownOpen", this.isDropdownOpen, this.selectId);
    },
    selectOption(option) {
      if (this.isMultiSelect) {
        const index = this.selectedOptions.findIndex(
          (item) => item.id === option.id,
        );
        if (index > -1) {
          this.selectedOptions.splice(index, 1);
        } else {
          this.selectedOptions.push(option);
        }
        this.$emit("update:selectedOption", this.selectedOptions);
      } else {
          this.optionTitle = option.title;
        this.isDropdownOpen = false;
        this.$emit("update:selectedOption", option);
        this.$emit("update:selectedOptionId", option.id);
        this.$emit("update:selectedOptionTitle", option.title);
      }
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.select-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
.select {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__label {
    position: absolute;
    color: #c6c6c6;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 0;
    z-index: 4;
    pointer-events: none;
    top: 33%;
    left: 30px;

    &_error {
      color: red;
      top: -17px;
      left: 17px;
      background: none;
      box-shadow: none;
    }
  }
  &__container {
    position: relative;
    display: flex;
    align-items: center;
  }
  &__selected-content {
    max-height: 20px;
    overflow: hidden;
  }
  &__dropdown {
    height: 52px;

    z-index: 3;
    background: white;
    width: 100%;
    border: 4px solid #ffce38;
    border-radius: 30px;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:focus {
      box-shadow: #ffce38 0 0 20px;
      border-color: #ffce38;
    }
  }

  &__arrow {
    transition: transform 0.2s;
    transform: rotate(0deg);

    &--open {
      transform: rotate(180deg);
    }
  }

  &__options {
    max-height: 200px;
    overflow-y: hidden;

    overflow-x: hidden;
    z-index: 2;
    position: absolute;
    top: 65%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 4px solid #ffce38;
    border-top: none;
    border-radius: 0 0 30px 30px;
    list-style: none;
    padding: 2px;
    margin: 0;
    &-list {
      max-height: 200px;
      overflow-y: auto;
      padding: 5px;
    }
    li {
      padding: 10px 20px;
      cursor: pointer;

      &:hover {
        background-color: #ffce38;
      }
    }
  }
}
.z-index {
  z-index: 5;
}
</style>