<template>
  <div
    class="takeout-layout"
    :class="['mr-' + takeout]"
  >
    <div
      ref="myDiv"
      class="takeout-layout__content"
      :class="[
        'takeout-circle takeout-circle_' +
          (takeout === 'bottom' ? 'left' : takeout),
        { upCircle: zIndex },
      ]"
      :style="`width: ${width}%`"
    >
      <slot></slot>
      <div
        v-if="takeout === 'bottom'"
        class="takeout-bottom"
        :style="{ [takeout]: `0px`, height: `${edgeDiff - 30}px` }"
      >
        <img src="@/assets/images/svg/takeout-bottom.svg" alt="" />
      </div>
    </div>
    <div class="takeout-line" :class="'takeout-line_' + takeout">
      <div
        v-if="takeout !== 'bottom'"
        class="line"
        :class="takeout"
        :style="{ [takeout]: `0px`, width: `${edgeDiff}px` }"
      />
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  name: "takeoutLayout",
  props: {
    takeout: {
      type: String,
      default: "right",
    },
    width: {
      type: Number,
    },
    zIndex: Boolean,
  },
  data() {
    return {
      edgeDiff: 0,
    };
  },
  mounted() {
    this.calculateEdgeDifference();

    window.addEventListener("resize", debounce(this.handleResize, 10));
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    calculateEdgeDifference() {
      const divElement = this.$refs.myDiv;

      if (divElement) {
        const divRect = divElement.getBoundingClientRect();
        const divEdgeX =
          this.takeout === "right" ? divRect.right : divRect.left;

        let viewportEdgeX = 0;
        if (this.takeout === "right" || this.takeout === "left") {
          viewportEdgeX = this.takeout === "right" ? window.innerWidth : 0;
        } else {
          viewportEdgeX = window.innerHeight - 220;
        }
        this.edgeDiff = Math.abs(viewportEdgeX - divEdgeX) + 10;
      }
    },
    handleResize() {
      this.calculateEdgeDifference();
    },
  },
};
</script>

<style lang="scss" scoped>
.mr-right {
  //margin-right: 15px;
}
.mr-left {
  //margin-left: 15px;
}
.takeout-layout {
  display: flex;
  align-items: center;
}
.takeout-layout__content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.takeout-circle {
  &_left::before,
  &_right::after {
    z-index: 4;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    content: "";
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    border: 10px solid #ffce38;
    box-shadow: rgba(0, 0, 0, 0.49) 0 0 1px;
  }
  &_left::before {
    left: -15px;
  }

  &_right::after {
    right: -15px;
  }
}
.takeout-line {
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 8px;
  display: flex;
  left: 0;

  .line {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &_right {
    .right {
      background: #ffce38;
    }
  }
  &_left {
    .left {
      background: #ffce38;
    }
  }
}
.takeout-bottom {
  position: absolute;
  top: 20px;
  left: -50px;
  &::before {
    content: "";
    z-index: -1;
    background: #ffce38;
    position: absolute;
    bottom: -30px;
    width: 10px;
    height: 100%;
  }
}
.upCircle::before, .upCircle::after {
  z-index: 6;
}
</style>