<template>
    <div class="about container-page">
      <title-page-component>
       <template v-slot:title>
        О сервисе
       </template>
     </title-page-component>
        <div class="description">
            <div class="text">
                    <p>
                Мобильное приложения «Центр робототехники» для подразделения «Центр робототехники Государственного бюджетного общеобразовательного учреждения „Президентский физико-математический лицей № 239“» (https://robot239.ru//) обеспечивает возможность производить учёт хранения, выдачи, возврата, ремонта и списания оборудования, учёт оборудования в разрезе наборов, комплектации, идентификацию комплектующих, наборов по QR-кодам, идентификацию пользователей по QR-кодам, оповещение пользователей о событиях и состояниях, связанных с учётом оборудования.
                    </p>
                    <br />
                    <p>Десктопная версия так же позволяет работать с базой данных оборудования, проводить инвентаризацию оборудования, формирование журналов и отчётности, связанной с хранением оборудования.</p>

            </div>
            <a class="download" download="rules.pdf" href="/rules.pdf">
                <img class="file" src="@/assets/images/svg/filePdf.svg" />
                <span>Скачать правила пользования наборами</span>
            </a>
            <img src="@/assets/images/about.png">
        </div>
    </div>
  </template>

  <script>
import TitlePageComponent from "@/components/ui/TitlePageComponent.vue";

  export default {
    name: "About",
    components: {
      TitlePageComponent
    },
    methods: {
    },
    data() {
      return {

      }
    }
  };
  </script>

  <style lang="scss" scoped>
  .download {
    display: flex;
    align-items: center;
    gap: 14px;
    font-size: 24px;
    line-height: 32px;
    text-decoration: underline;
  }
  .file {
    height: 100px;
    width: auto;
 }
  .description {
    > .text {
        z-index: 2;
    }
    position: relative;
    width: 814px;
    display: flex;
    flex-direction: column;
    gap: 100px;
font-size: 18px;
line-height: 25px;

> img {
        position: absolute;
        width: 760px;
        height: auto;
        left: 100%;
    margin-left: -150px;
    top: -30px;
    }
  }
  </style>