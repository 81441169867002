<template>
  <modal-component @close-modal="eventClose">
    <template v-slot:title>
      <div class="modal-title">Выдача набора</div>
    </template>
    <template v-slot:content>
      <div class="modal-register" @click.stop>
        <takeout-layout>
          <select-component v-model:selected-option-id="isGrantedToID" class="input-component" label="Выбрать пользователя" :options="users" v-model:selected-option="grantedSelectUser" />
        </takeout-layout>
        <takeout-layout>
          <input-field v-model:value="isGrantedDeadlineDt" class="input-component" label="Срок сдачи" type="date" />
        </takeout-layout>
        <takeout-layout>
          <button-component>
            Сканировать
          </button-component>
        </takeout-layout>
        <takeout-layout>
          <button-component @click="grantedKit">
            Подтвердить
          </button-component>
        </takeout-layout>
      </div>
    </template>
  </modal-component>
</template>
<script>
import axios from "axios";
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import ModalComponent from "@/components/ui/ModalComponent.vue";
import InputField from "@/components/ui/InputField.vue";
import SelectComponent from "@/components/ui/SelectComponent.vue";
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";

export default {
  name: "GetKitModal",
  components: {TakeoutLayout, SelectComponent, InputField, ModalComponent, ButtonComponent},
  props: {
    isGrantedKitID: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      isGrantedToID: null,
      isGrantedDeadlineDt: null,
      grantedSelectUser: null,

    }
  },
  methods: {
    grantedKit() {
      axios.post('main/kit/grant', { kitId: this.isGrantedKitID, grantedToId: this.isGrantedToID, deadlineDt: this.isGrantedDeadlineDt})
          .then(() => {
            this.getKit()
          })
          .catch(err => {
            console.log(err)})
    },
    eventClose() {
      this.$emit('closeModal')
    },
  },
  computed: {
    users() {
      return this.$store.getters['users/usersForSelect']
    },
  },
  mounted() {
    const currentDate = new Date();
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);

    const day = String(nextDate.getDate()).padStart(2, '0');
    const month = String(nextDate.getMonth() + 1).padStart(2, '0');
    const year = nextDate.getFullYear();

    this.isGrantedDeadlineDt = `${year}-${month}-${day}`;
  }
}
</script>
<style scoped lang="scss">

.modal-register {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.input-component {
  width: 300px;
}
</style>