<template>
  <section class="dashboard">
    <div class="dashboard-content">
      <div class="notification-wrapper">
      </div>
      <div class="dashboard-wrapper">
        <header-component v-if="userLoaded" class="dashboard-header" />
        <div class="content">
          <router-view :key="$route.path"></router-view>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HeaderComponent from "@/components/header/HeaderComponent.vue";

export default {
  name: "DashboardLayout",
  components: {HeaderComponent},
  data: () => ({}),
  methods: {
  },
  beforeUnmount() {
  },
  computed: {
    userLoaded() {
      return this.$store.state.auth?.user != null;
    }
  },
  created() {
  },
  watch: {
    userLoaded: function() {
    }
  }
};
</script>
<style lang="scss" scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &-wrapper {
    width: 100%;
    margin: 0 auto;
  }
  &-content {
    display: flex;
    height: 100%;
    min-height: 100vh;
  }
}

// important
@media(max-width: 768px){
  .menu-component.active {
    max-width: unset;
    width: 100%;
  }
  .menu-component.active ~ .dashboard-wrapper{
    position: absolute;
  }
}
// important
</style>
