import { dateFormat } from "@/helpers/dateFormat";

export function handleUserData(response) {
  const data = response.data.items.map((item) => {
    return {
      id: item.id,
      data: {
        code: item.fullName,
        birthday: dateFormat(item.birthday),
        position:
          item.userGroup === "Student"
            ? "Ученик"
            : item.userGroup === "Teacher"
            ? "Преподаватель"
            : item.userGroup === "Admin"
            ? "Администратор"
            : item.userGroup === "Superuser"
            ? "Суперпользователь"
            : null,
        groups: item.workshops,
        phone: item.phoneNumber,
        email: item.email,
        extra: item.comment,
        isDeleted: item.isDeleted ? "Удален" : "Активен",
      },
    };
  });

  return {
    totalCount: response.data.totalCount,
    data: data,
    count: data.length,
  };
}
