<template>
  <div>
    <label>
      <input type="checkbox" :checked="isChecked" @change="toggleCheckbox" />
      <span :class="{ checked: isChecked }">
        <i class="checkmark"></i>
      </span>
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    isChecked: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
  },
  methods: {
    toggleCheckbox() {
      this.$emit("update:is-checked", !this.isChecked);
    },
  },
};
</script>

<style scoped>
label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

input[type="checkbox"] {
  display: none;
}

span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #ceab30;
  margin-right: 10px;
  border-radius: 4px;
  background-color: white;
  position: relative;
}

span.checked {
  background-color: #ceab30;
  border-color: #ceab30;
}

.checkmark {
  position: absolute;
  top: 3%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 12px;
  border: 2px solid #fff;
  border-top: none;
  border-left: none;
  transform: rotate(45deg);
  display: none;
}

span.checked .checkmark {
  display: block;
}
</style>
