<template>
  <div @click="refreshValidators">
    <auth-layout>
      <template v-slot:title>
        <span class="title-yellow">Изменить</span> <span>пароль</span>
      </template>
      <template v-slot:inputs>
        <takeout-layout>
          <input-field class="input-component" @input="validateCurrentPassword" @keydown.space="(event) => event.preventDefault()" v-model:value="this.currentPassword" label="Старый пароль" :label-error="validate.currentPass || recoveryErrorMessage?.code"
                       type="password"/>
        </takeout-layout>
        <takeout-layout>
          <input-field class="input-component" @input="validateNewPassword" @keydown.space="(event) => event.preventDefault()" v-model:value="this.newPassword" label="Новый пароль" :label-error="validate.matchPass"
                       type="password"/>
        </takeout-layout>
        <takeout-layout>
          <input-field class="input-component" @input="validateConfirmNewPassword" @keydown.space="(event) => event.preventDefault()" v-model:value="this.newPasswordRepeat" label="Повторите новый пароль"
                       type="password"/>
        </takeout-layout>
      </template>

      <template v-slot:other></template>

      <template v-slot:submit>

        <div @click.self="disableRecoveryInstructionsModal" v-if="isInstructionsModal" class="h-[100vh] w-[100vw] fixed
                                               backdrop-blur
                                               flex items-center justify-content-center" >
          <div @click.stop class="h-[250px] w-[320px] md:h-[250px] md:w-[450px] bg-white
                      border-amber-300 border-2 flex flex-col justify-content-center
                      gap-20 items-center text-center " >
            <p  class="">Пароль успешно изменен!</p>
            <button class="bg-amber-400 border-1 w-[175px] h-[35px] hover:cursor-pointer z-50"
                    @click.self="disableRecoveryInstructionsModal">В личный кабинет
            </button>
          </div>
        </div>

        <div class="login-button-wrapper">
          <takeout-layout takeout="left">
            <button-component class="login-button" @click.stop="onSubmit()">
              Изменить пароль
              <template #icon-right>
                <img src="@/assets/images/svg/arrow-next.svg" alt="">
              </template>
            </button-component>
          </takeout-layout>
        </div>
      </template>
    </auth-layout>
  </div>
</template>

<script>
import InputField from "@/components/ui/InputField.vue";
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
import buttonComponent from "@/components/ui/ButtonComponent.vue";
import AuthLayout from "@/modules/auth/AuthLayout.vue";
export default {
  name: "resetPass",
  components: {
    AuthLayout,
    buttonComponent,
    TakeoutLayout,
    InputField
  },
  data() {
    return {
      userId: JSON.parse(localStorage.getItem('user')).profile.id,
      currentPassword: null,
      newPassword: null,
      newPasswordRepeat: null,
      isPasswordsMatch: true,
      isCurrentPasswordFieldEmpty: false,
      validate: {
        currentPass: '',
        matchPass: ''
      }
    }
  },
  computed: {
    errors() {
      return this.$store.state.auth.errorsLogin
    },
    isUserExists() {
      return !this.$store.state.auth.isUserExists
    },
    isInstructionsModal() {
      return this.$store.state.auth.isRecoveryInstructionsModal
    },
    recoveryErrorMessage() {
      return this.$store.state.auth?.recoveryErrorMessage?.length ? this.$store.state.auth?.recoveryErrorMessage[0] : null
    },
    passwordsMatchCheck() {
      return (this.newPassword === this.newPasswordRepeat) && (this.newPassword?.length > 0)
    }
  },
  methods: {
    async onSubmit() {
      if (!this.currentPassword?.length) {
        this.isCurrentPasswordFieldEmpty = true
        this.validate.currentPass = 'Обязательное поле'
      } else {
        this.validate.currentPass = ''
      }
      if (this.passwordsMatchCheck) {
        this.validate.matchPass = ''
        let userData = {
          userId: this.userId,
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
          newPasswordRepeat: this.newPasswordRepeat,
        }
        await this.$store.dispatch('auth/changePass', userData)
      }
      else {
        this.validate.matchPass = 'Пароли не совпадают'
        this.isPasswordsMatch = false
      }
    },
    disableRecoveryInstructionsModal() {
      this.$store.dispatch('auth/disableRecoveryInstructionsModal')
      this.$router.push('/')
    },
    refreshValidators() {
      this.isPasswordsMatch = ''
      this.isCurrentPasswordFieldEmpty = false
    },
    validateCurrentPassword() {
      this.currentPassword = this.currentPassword.replace(/[^a-zA-Z0-9\s.,\-=/&?:%;№#$@()^*|!_]/g, "");
    },
    validateNewPassword() {
      this.newPassword = this.newPassword.replace(/[^a-zA-Z0-9\s.,\-=/&?:%;№#$@()^*|!_]/g, "");
    },
    validateConfirmNewPassword() {
      this.newPasswordRepeat = this.newPasswordRepeat.replace(/[^a-zA-Z0-9\s.,\-=/&?:%;№#$@()^*|!_]/g, "");
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.form) .login-button-wrapper {
  margin-top: 20px;
}
</style>