<template>
  <div
    class="btn"
    :class="{ active: isActive, 'not-active': !isActive }"
  >
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<script>
export default {
  name: "burger-menu",
  props: {
    isActive: Boolean,
  },
};
</script>

<style scoped lang="scss">
.btn {
  display: flex;
  align-items: center;
  flex-direction: column;

  left: 30px;
  top: 60px;
  width: 70px;
  @media (max-width: 1350px) {
    top: 45px;
  }
  @media (max-width: 1240px) {
    top: 40px;
  }
  @media (max-width: 576px) {
    left: 0;
    top: 25px;

  }
  cursor: pointer;
}
.btn.active {
  width: 60px;
  left: auto;
  top: 10px;
  right: 10px;
}

span {
  display: block;
  width: 80%;
  border-radius: 3px;
  height: 5px;
  background: #4F4F4F;
  transition: all 0.3s;
  position: relative;
}

span + span {
  margin-top: 5px;
}
.active span + span {
  margin-top: 18px;
}
.active span:nth-child(1) {
  animation: ease 0.7s top forwards;
}
.active span {
}
.not-active span:nth-child(1) {
  animation: ease 0.7s top-2 forwards;
}

.active span:nth-child(2) {
  animation: ease 0.7s scaled forwards;
}

.not-active span:nth-child(2) {
  animation: ease 0.7s scaled-2 forwards;
}

.active span:nth-child(3) {
  animation: ease 0.7s bottom forwards;
}

.not-active span:nth-child(3) {
  animation: ease 0.7s bottom-2 forwards;
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 22px;
    transform: rotate(0);
  }
  100% {
    top: 23px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 22px;
    transform: rotate(45deg);
  }
  50% {
    top: 22px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 22px;
    transform: rotate(0);
  }
  100% {
    bottom: 22px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 22px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 22px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

</style>
