<template>
  <div @keydown.enter="onSubmit()">
    <auth-layout>
      <template v-slot:title>
        <span class="title-yellow">Добро</span><span>пожаловать</span>
      </template>
      <template v-slot:inputs>
        <takeout-layout v-if="loginWithPhone">
          <input-field class="input-component" v-model:value="login" label="Номер телефона" :label-error="errors"
                       type="tel"/>
        </takeout-layout>
        <takeout-layout v-else>
          <input-field class="input-component" v-model:value="login" label="Адрес электронной почты" :label-error="errors"
                       type="email" @input="validateEmail"/>
        </takeout-layout>
        <takeout-layout>
          <input-field class="input-component" @input="validatePassword" v-model:value="password" label="Пароль"
                       type="password"
                      />
        </takeout-layout>
      </template>
      <template v-slot:other>
        <div class="additional-link" @click="selectWithLogin()">
          <p v-if="loginWithPhone">
          Войти по адресу электронной почты
          </p>
          <p v-else>
            Войти по номеру телефона
          </p>
        </div>
      </template>
      <template v-slot:submit>
        <div class="login-button-wrapper">
          <takeout-layout takeout="bottom">
            <button-component class="login-button" :icon="'@/assets/images/svg/arrow-next.svg'" @click="onSubmit()">
              Войти
              <template #icon-right>
                <img src="@/assets/images/svg/arrow-next.svg" alt="">
              </template>
            </button-component>
          </takeout-layout>
        </div>
        <div @click="this.$router.push('/resetPass')" class="additional-link reset-pass">
          Восстановить пароль
        </div>
      </template>
      <template #links>
        <a href="https://apps.apple.com/ru/app/робот239/id6463115305" target="_blank">
          <img src="@/assets/images/svg/appstore.svg" alt="">
        </a>
        <a href="https://lic239.test.runetsoft.ru/mp239-release.apk" target="_blank">
          <img src="@/assets/images/svg/android-apk.svg" alt="">
        </a>
      </template>
    </auth-layout>
  </div>
</template>
<script>
import InputField from "@/components/ui/InputField.vue";
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
import buttonComponent from "@/components/ui/ButtonComponent.vue";
import AuthLayout from "@/modules/auth/AuthLayout.vue";

export default {
  name: "Login",
  components: { AuthLayout, buttonComponent, TakeoutLayout, InputField },
  data() {
    return {
      login: null,
      password: null,
      passVision: false,
      loginWithPhone: true,
    }
  },
  created() {
    const returnUrl = this.$route.query.returnUrl || '/'
    this.$store.dispatch('auth/setReturnUrl', returnUrl)
    if (this.$store.state.auth.user)
      this.$router.push(returnUrl)
  },
  computed: {
    errors() {
      return this.$store.state.auth.errorsLogin
    }
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true
      await this.$store.dispatch('auth/login', {username: this.login, password: this.password})
    },
    selectWithLogin() {
      this.loginWithPhone = !this.loginWithPhone
      this.$store.dispatch('auth/removeError')
      this.login = ''
    },
    validatePassword() {
      this.password = this.password.replace(/[^a-zA-Z0-9\s.,\-=/&?:%;№#$@()^*|!_]/g, "");
    },
    validateEmail() {
      if (!this.loginWithPhone) {
        this.login = this.login.replace(/[^a-zA-Z0-9\s.,\-=/&?:%;№#$@()^*|!_]/g, "")
      }
    },
    resetError() {
      this.$store.dispatch('auth/removeError')
    }
  },
  watch: {
    errors() {
        if (this.errors) {
          this.validAuth = 'Неверные учетные данные'
        } else {
          this.validAuth = ''
        }
    }
  }
};
</script>
<style scoped lang='scss'>
.form__submit .reset-pass {
  text-align: center;
}
.download-mobile {
}
</style>