<template>
<div class="edit-account-current">
  <takeout-layout><input-field class="input-component" v-model:value="newEmail" label="Адрес электронной почты" :label-error="validate.email"
                               type="email"/></takeout-layout>
  <takeout-layout><input-field class="input-component" v-model:value="newPhone" label="Номер телефона" :label-error="validate.phone"
                               type="tel"/></takeout-layout>
  <takeout-layout><input-field v-model:value="newComment" label="Дополнительная информация" type="textarea" class="input-component" /></takeout-layout>
  <takeout-layout><button-component @click="onSubmit()" class="button-component">Сохранить</button-component></takeout-layout>
</div>
</template>

<script>
import InputField from "@/components/ui/InputField.vue";
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import axios from "axios";
import {checkAllFieldsEmpty, checkFieldEmpty} from "@/helpers/validate";
export default {
  name: "editAccountCurrent",
  components: {ButtonComponent, TakeoutLayout, InputField},
  props: {
    user: Object,
  },
  data() {
    return {
      newEmail: '',
      newPhone: '',
      newComment: '',
      validate: {
        email: '',
        phone: ''
      },
    }
  },
  methods: {
    onSubmit() {
      this.checkedValidated()
      if( this.isValidate ) {
        axios.post('account/edit', {email: this.newEmail, phoneNumber: this.newPhone, comment: this.newComment})
          .then(res => {this.$store.dispatch('auth/getEditAccountCurrent', res)})
          .catch(error => {
            console.log(error)
          })
        this.$emit('closeModal')
        this.clearValidated()
      }
    },
    checkedValidated() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const messEmail = 'Введите верный формат почты'
      const messPhone = 'Номер должен содержать 11 цифр'
      const v = this.validate
      v.phone = checkFieldEmpty(this.newPhone)
      v.phone = (this.newPhone?.length === 16) ? '' : messPhone
      v.email = checkFieldEmpty(this.newEmail)
      if (!emailRegex.test(this.newEmail)) {
        v.email = messEmail
      } else {
        v.email = ''
      }
    },
    clearValidated() {
      this.validate.phone = ''
      this.validate.email = ''
    }
  },
  computed: {
    isValidate() {
      return checkAllFieldsEmpty(this.validate)
    },
  },
  mounted() {
    this.newEmail = this.user.email
    this.newPhone = this.user.phoneNumber
    this.newComment = this.user.comment
  }
}
</script>

<style lang="scss" scoped>
.edit-account-current {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.input-component {
  width: 300px;
}
.button-component {
  width: 300px;
}

</style>