<template>
  <div class="bg-main flex flex-column justify-content-between rounded-3xl rounded-tl-[5rem] rounded-bl-[2rem] w-[200px]">
    <div class="rounded-full border-[10px] w-32 h-32 border-main bg-white flex items-center justify-center relative pl-6">
      <slot name="image"></slot>
    </div>
    <div class="p-4 gap-4 flex flex-col text-2xl font-bold text-dark-gray">
      <slot >Detail</slot>
<!--      <textarea placeholder="Комментарий" :value="detailCardInfo.comment" class="w-full h-32 text-lg font-normal p-3" />-->
    </div>
    <div class="flex gap-2 text-md items-center">
      <div class="border-[10px] w-14 h-14 border-main rounded-full shadow-sm bg-white">
        <img class="w-full"
          :src="require(`@/assets/images/svg/${
          detailCardInfo.stateId === 1
          ? 'check-green.svg'
          : 'check-red.svg'}`)"
          alt="">
      </div>
      <span>{{ detailCardInfo.stateTitle }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "detailCardComponent",

  props: {
    detailCardInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
    }
  }
}
</script>