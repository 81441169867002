import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "jquery/dist/jquery.min";
import "jquery-ui-dist/jquery-ui.min";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "admin-lte/dist/js/adminlte.min";
import "admin-lte/dist/css/adminlte.min.css";
import "jquery-ui-dist/jquery-ui.min.css";
import "./assets/theme/default/style.scss";
import './assets/theme/default/tailwind.css'
import moment from "moment";
import { configAxios } from "@/common/axios";
import Select2 from "vue3-select2-component";
import "select2/dist/js/i18n/ru";


configAxios();
const app = createApp(App)
  .use(store)
  .use(router)

app.config.globalProperties.$momentFormat = (date, format) =>
  date != null ? moment(date).locale('ru').format(format) : "";
app.component("Select2", Select2);

app.mount("#app");
