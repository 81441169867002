<template>
  <div>
    <auth-layout>
      <template v-slot:title>
        <span class="title-yellow">Создать</span> <span>пароль</span>
      </template>
      <template v-slot:inputs>
        <takeout-layout>
          <input-field
              @input="validateNewPassword"
            class="input-component"
            @keydown.space="(event) => event.preventDefault()"
            v-model:value="this.newPassword"
            label="Новый пароль"
            :label-error="validate.matchPass"
            type="password"
          />
        </takeout-layout>
        <takeout-layout>
          <input-field
              @input="validateNewPasswordRepeat"
            class="input-component"
            @keydown.space="(event) => event.preventDefault()"
            v-model:value="this.newPasswordRepeat"
            label="Повторите новый пароль"
            type="password"
          />
        </takeout-layout>
      </template>

      <template v-slot:other></template>

      <template v-slot:submit>
        <div class="login-button-wrapper">
          <takeout-layout takeout="left">
            <button-component class="login-button" @click.stop="onSubmit()">
              Сохранить пароль
              <template #icon-right>
                <img src="@/assets/images/svg/arrow-next.svg" alt="" />
              </template>
            </button-component>
          </takeout-layout>
        </div>
      </template>
    </auth-layout>
    <modal-component @close-modal="this.error = null" v-if="error">
      <template #title>
        Ошибка!
      </template>
      <template #content>
        {{ this.error }}
      </template>
    </modal-component>
  </div>
</template>

<script>
import AuthLayout from "@/modules/auth/AuthLayout.vue";
import InputField from "@/components/ui/InputField.vue";
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
import buttonComponent from "@/components/ui/ButtonComponent.vue";
import { authAxios } from "@/common/axios";
import ModalComponent from "@/components/ui/ModalComponent.vue";

export default {
  name: "CreatePass",
  components: {
    ModalComponent,
    buttonComponent,
    TakeoutLayout,
    InputField,
    AuthLayout,
  },
  data() {
    return {
      newPassword: null,
      newPasswordRepeat: null,
      isPasswordsMatch: true,
      error: null,
      validate: {
        matchPass: "",
      },
    };
  },
  methods: {
    async onSubmit() {
      try {
        if (this.newPassword === this.newPasswordRepeat) {
          const res = await authAxios.put(
            "auth/PasswordRecovery/ResetPassword",
            {
              userId: +this.$route.query?.id,
              token: this.$route.query?.code,
              newPassword: this.newPassword,
            },
          );
          this.validate.matchPass = "";
          this.error = '';
          this.$router.push("/");
        } else {
          this.validate.matchPass = "Пароли должны совпадать";
        }
      } catch (error) {
        console.error("Ошибка запроса:", error);
        this.validate.matchPass = error.response.data.errors[0].description;
      }
    },
    validateNewPassword() {
      this.newPassword = this.newPassword.replace(/[^a-zA-Z0-9\s.,\-=/&?:%;№#$@()^*|!_]/g, "");
    },
    validateNewPasswordRepeat() {
      this.newPasswordRepeat = this.newPasswordRepeat.replace(/[^a-zA-Z0-9\s.,\-=/&?:%;№#$@()^*|!_]/g, "");
    },
  },
  mounted() {
    console.log(this.$route.query);
  },
};
</script>

<style scoped lang="scss">
:deep(.form) .login-button-wrapper {
  margin-top: 20px;
}
</style>