<template>
  <div class="lk container-page">
    <title-page-component :rout-back-view="true">
      <template v-slot:title>
        Карточка пользователя
        <span class="title-span">{{ userInfo?.data?.fullName }}</span>
      </template>
    </title-page-component>
    <div class="lk__user-info">
      <user-card-component :user="userInfo?.data" />
      <div class="lk__other-info">
        <div class="lk__qr" v-if="userInfo?.data?.qr">
          <vue-qrcode
            class="!w-[260px] !h-[260px] my-4"
            :value="userInfo?.data?.qr"
          />
        </div>
        <div class="lk__edit-info">
          <takeout-layout takeout="right">
            <button-component @click="onClickPrintQR">
              <template v-slot:icon-left>
                <img
                  src="@/assets/images/svg/printQR.svg"
                  class="w-full h-full"
                  alt=""
                />
              </template>
              Распечатать QR
            </button-component>
          </takeout-layout>
        </div>
      </div>
    </div>
    <div class="lk__edit-info">
      <takeout-layout takeout="left">
        <button-component @click="edit"> Редактировать </button-component>
      </takeout-layout>
    </div>
  </div>
</template>
<script>
import TitlePageComponent from "@/components/ui/TitlePageComponent.vue";
import UserCardComponent from "@/components/UserCardComponent.vue";
import buttonComponent from "@/components/ui/ButtonComponent.vue";
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
import VueQrcode from "vue-qrcode";
import api from "@/common/api/api";
import utils from "@/common/utils";
import axios from "axios";

export default {
  name: "Home",
  components: {
    TakeoutLayout,
    buttonComponent,
    UserCardComponent,
    TitlePageComponent,
    VueQrcode,
  },

  data() {
    return {
      userInfo: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.accountCurrent;
    },
  },
  mounted() {
    this.$store.dispatch("auth/getAccountCurrent");
    this.getUserInfo();
  },
  methods: {
    async getUserInfo() {
      try {
        this.userInfo = await axios.get("user/" + this.$route.params.id);
      } catch (e) {
        console.log(e);
      }
    },
    onClickPrintQR() {
      let url = api.getQRPrintOne(this.user.id, "user");
      utils.downloadAndPrintJson(url, null);
    },
    closeModal() {
      this.isEditAccountCurrent = !this.isEditAccountCurrent;
    },
    edit() {
      this.$router.push({
        path: "/createUser",
        query: { id: this.$route.params.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.router-link {
  width: 100%;
}
.title-span {
  font-weight: 700;
  color: #ffce38;
}
.lk {
  &__edit-info {
    width: 100%;
  }
  &__user-info {
    justify-content: space-between;
    margin: 50px 0;
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: center;
      gap: 60px;
    }
  }

  &__other-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 260px;
    align-items: center;
    @media (max-width: 768px) {
      width: auto;
    }
  }

  &__qr {
    width: 300px;
    height: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
    }

    img {
      width: 300px;
      height: 300px;
      @media (max-width: 768px) {
      }
    }
  }

  &__other-info-list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    li {
      font-size: 18px;
      font-weight: 800;
      line-height: 25px;
      text-align: right;

      span {
        color: #ffce38;
        margin: 0 10px;
      }
    }
  }
}
</style>
