import { dateFormat } from "@/helpers/dateFormat";
export function handleKitData(response) {
  const data = response.data.items.map((item) => {
    return {
      id: item.id,
      grantedToId: item.grant?.grantedToId,
      data: {
        id: item.id,
        code: item.code,
        feature: item.feature,
        classroom: item.classroomTitle,
        grantedTo: item.grant?.isCompleted ? null : item.grant?.grantedTo,
        grantedDt: item.grant?.isCompleted
          ? null
          : dateFormat(item?.grant?.grantedDt),
        deadlineDt: item.grant?.isCompleted
          ? null
          : dateFormat(item?.grant?.deadlineDt),
        state: item.stateTitle,
      },
    };
  });

  return {
    totalCount: response.data.totalCount,
    data: data,
    count: data.length,
  };
}
