<template>
  <header class="header">
    <div class="container-page">
      <nav class="navbar" :class="{ 'navbar-burger': burgerIsActive }">
        <div class="user-wrapper" v-if="user">
          <div class="icon-takeout-wrapper-up">
            <svg
                width="136"
                height="81"
                viewBox="0 0 136 81"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_f_545_2968)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M122.566 68H100.781C92.7665 68 85.5262 63.216 82.3831 55.8439L4 -128H14.871L91.5819 51.9219C93.1535 55.608 96.7736 58 100.781 58H122.566V68Z"
                    fill="#FFA51F"
                />
              </g>
              <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M122.566 68H100.781C92.7665 68 85.5262 63.216 82.3831 55.8439L4 -128H14.871L91.5819 51.9219C93.1535 55.608 96.7736 58 100.781 58H122.566V68Z"
                  fill="#FFCE38"
              />
              <g filter="url(#filter1_f_545_2968)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M129 74.9122V50.0878C126.812 48.7627 124.245 48 121.5 48C113.492 48 107 54.4919 107 62.5C107 70.5081 113.492 77 121.5 77C124.245 77 126.812 76.2373 129 74.9122Z"
                    fill="#FFA51F"
                />
              </g>
              <circle
                  cx="14.5"
                  cy="14.5"
                  r="9.5"
                  transform="matrix(1 0 0 -1 107 77)"
                  fill="white"
                  stroke="#FFCE38"
                  stroke-width="10"
              />
              <defs>
                <filter
                    id="filter0_f_545_2968"
                    x="0"
                    y="-132"
                    width="126.566"
                    height="204"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                  />
                  <feGaussianBlur
                      stdDeviation="2"
                      result="effect1_foregroundBlur_545_2968"
                  />
                </filter>
                <filter
                    id="filter1_f_545_2968"
                    x="103"
                    y="44"
                    width="30"
                    height="37"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                  />
                  <feGaussianBlur
                      stdDeviation="2"
                      result="effect1_foregroundBlur_545_2968"
                  />
                </filter>
              </defs>
            </svg>
          </div>
          <div class="user">
            <div class="notices-mobile">
              <div class="notices-mobile__img">
                <img src="@/assets/images/svg/bell-notices.svg" alt="user" />
                <p class="notices-mobile__number">9</p>
              </div>
            </div>
            <router-link to="/">
              <div class="user__img">
                <img
                    v-if="accountCurrent.image"
                    :src="accountCurrent.image"
                    alt="user"
                />
                <img v-else src="@/assets/images/avatar.png" alt="user" />
              </div>
            </router-link>
            <p class="user__name">
              {{ user.surname + "&nbsp;" + user.name }}
            </p>
          </div>
        </div>
        <div class="menu">
          <div class="notices-wrapper">
            <div class="icon-takeout-wrapper-up">
              <svg
                  width="136"
                  height="81"
                  viewBox="0 0 136 81"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_f_545_2968)">
                  <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M122.566 68H100.781C92.7665 68 85.5262 63.216 82.3831 55.8439L4 -128H14.871L91.5819 51.9219C93.1535 55.608 96.7736 58 100.781 58H122.566V68Z"
                      fill="#FFA51F"
                  />
                </g>
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M122.566 68H100.781C92.7665 68 85.5262 63.216 82.3831 55.8439L4 -128H14.871L91.5819 51.9219C93.1535 55.608 96.7736 58 100.781 58H122.566V68Z"
                    fill="#FFCE38"
                />
                <g filter="url(#filter1_f_545_2968)">
                  <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M129 74.9122V50.0878C126.812 48.7627 124.245 48 121.5 48C113.492 48 107 54.4919 107 62.5C107 70.5081 113.492 77 121.5 77C124.245 77 126.812 76.2373 129 74.9122Z"
                      fill="#FFA51F"
                  />
                </g>
                <circle
                    cx="14.5"
                    cy="14.5"
                    r="9.5"
                    transform="matrix(1 0 0 -1 107 77)"
                    fill="white"
                    stroke="#FFCE38"
                    stroke-width="10"
                />
                <defs>
                  <filter
                      id="filter0_f_545_2968"
                      x="0"
                      y="-132"
                      width="126.566"
                      height="204"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur
                        stdDeviation="2"
                        result="effect1_foregroundBlur_545_2968"
                    />
                  </filter>
                  <filter
                      id="filter1_f_545_2968"
                      x="103"
                      y="44"
                      width="30"
                      height="37"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur
                        stdDeviation="2"
                        result="effect1_foregroundBlur_545_2968"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
            <div class="notices" @click="closeModal">
              <div class="notices__img">
                <img src="@/assets/images/svg/bell-notices.svg" alt="user" />
                <p v-if="isNotificationsEnabled" class="notices__number">9</p>
                <p
                    v-else
                    class="position-absolute text-red-500 font-weight-bold text-5xl top-[6px] left-[23px]"
                >
                  /
                </p>
              </div>
            </div>
            <modal-component @close-modal="closeModal" v-if="isNoticesOpen">
              <template v-slot:title>
                <div class="self-start flex gap-4 items-center">
                  <span class="text-4xl">Уведомления</span>
                </div>
              </template>
              <template v-slot:content>
                <div
                    class="flex flex-col items-end gap-5 overflow-hidden w-[712px]"
                >
                  <div class="d-flex flex-column align-self-start text-lg">
                    <checkbox
                        label="Получать push-уведомления о новых системных сообщениях"
                        :is-checked="isCheckBoxNotificationsEnabled"
                        @update:is-checked="getSystemsNotification"
                    />
                    <checkbox
                        label="Получать push-уведомления о новых личных сообщениях"
                        :is-checked="isCheckBoxMessagesEnabled"
                        @update:is-checked="getMessagesNotification"
                    />
                  </div>
                  <div
                      v-if="reverseNotifications.length"
                      class="flex flex-col text-lg pr-2 gap-2 w-full items-start overflow-auto mt-0 font-bold"
                      ref="scrollContainer"
                      @scroll="handleScroll"
                  >
                    <div
                        class="flex justify-between w-full gap-4"
                        v-for="{ text, created } in reverseNotifications"
                    >
                      <span
                          v-html="
                          text.replace(
                            /([A-Z]+)/,
                            '<span class=\'text-main\'>$1</span>',
                          )
                        "
                      />
                      <div class="flex gap-1">
                        <span class="text-main">{{ formatDate(created) }}</span>
                        <span>{{ formatTime(created) }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="w-full text-center" v-else>
                    Уведомления отсутствуют
                  </div>
                  <takeout-layout class="mr-3">
                    <button-component @click="onClickSubmitNotificationsSelection">
                      Сохранить и закрыть
                    </button-component>
                  </takeout-layout>
                </div>
              </template>
            </modal-component>
          </div>
          <div class="burger-menu-wrapper">
            <div class="icon-takeout-wrapper-up">
              <svg
                  width="136"
                  height="81"
                  viewBox="0 0 136 81"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_f_545_2968)">
                  <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M122.566 68H100.781C92.7665 68 85.5262 63.216 82.3831 55.8439L4 -128H14.871L91.5819 51.9219C93.1535 55.608 96.7736 58 100.781 58H122.566V68Z"
                      fill="#FFA51F"
                  />
                </g>
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M122.566 68H100.781C92.7665 68 85.5262 63.216 82.3831 55.8439L4 -128H14.871L91.5819 51.9219C93.1535 55.608 96.7736 58 100.781 58H122.566V68Z"
                    fill="#FFCE38"
                />
                <g filter="url(#filter1_f_545_2968)">
                  <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M129 74.9122V50.0878C126.812 48.7627 124.245 48 121.5 48C113.492 48 107 54.4919 107 62.5C107 70.5081 113.492 77 121.5 77C124.245 77 126.812 76.2373 129 74.9122Z"
                      fill="#FFA51F"
                  />
                </g>
                <circle
                    cx="14.5"
                    cy="14.5"
                    r="9.5"
                    transform="matrix(1 0 0 -1 107 77)"
                    fill="white"
                    stroke="#FFCE38"
                    stroke-width="10"
                />
                <defs>
                  <filter
                      id="filter0_f_545_2968"
                      x="0"
                      y="-132"
                      width="126.566"
                      height="204"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur
                        stdDeviation="2"
                        result="effect1_foregroundBlur_545_2968"
                    />
                  </filter>
                  <filter
                      id="filter1_f_545_2968"
                      x="103"
                      y="44"
                      width="30"
                      height="37"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur
                        stdDeviation="2"
                        result="effect1_foregroundBlur_545_2968"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
            <div class="burger-menu">
              <menu-component
                  @isActiveBurgerMenu="getActiveMenu"
                  class="burger-menu-component"
              />
            </div>
            <div class="icon-takeout-wrapper-down">
              <svg
                  width="119"
                  height="196"
                  viewBox="0 0 119 196"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.434105 0H22.2193C30.2335 0 37.4738 4.78399 40.6169 12.1561L119 196H108.129L31.4181 16.0781C29.8465 12.392 26.2264 10 22.2193 10H0.434105V0Z"
                    fill="#FFCE38"
                />
              </svg>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import MenuComponent from "@/components/header/MenuComponent.vue";
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import ModalComponent from "@/components/ui/ModalComponent.vue";
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
import Checkbox from "@/components/ui/Checkbox.vue";
import api from "@/common/api/api";
import axios from "axios";

export default {
  name: "HeaderComponent",
  components: {
    MenuComponent,
    ModalComponent,
    TakeoutLayout,
    ButtonComponent,
    Checkbox,
  },
  data() {
    return {
      unreadMessageCount: 0,
      isNoticesOpen: false,
      maxNotifications: false,
      notifications: [],
      burgerIsActive: false,
      isCheckBoxNotificationsEnabled: null,
      isNotificationsEnabled: null,
      isCheckBoxMessagesEnabled: null,
      isMessagesEnabled: null,
      page: 0,
    };
  },
  computed: {
    reverseNotifications() {
      return [...this.notifications].sort(
          (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime(),
      );
    },
    unreadCount() {
      return this.$store.state.messenger?.unreadMessages > 0;
    },
    user() {
      return this.$store.state.auth.user.profile;
    },
    accountCurrent() {
      return this.$store.state.auth.accountCurrent;
    },
  },
  watch: {
    unreadCount() {
      if (this.unreadCount === true) {
        this.updateUnreadMessageCount();
        this.$store.commit("messenger/updateUnread", 0);
      }
    },
    accountCurrent: {
      handler() {
        this.isNotificationsEnabled =
            this.$store.state.auth.accountCurrent.settings.pushNotificationsEnabled;
        this.isMessagesEnabled =
            this.$store.state.auth.accountCurrent.settings.pushMessagesEnabled;
      },
      deep: true,
    },

  },
  created() {
    this.loadNotifications();
  },

  methods: {
    formatDate(date) {
      return Intl.DateTimeFormat("ru", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }).format(new Date(date));
    },
    formatTime(date) {
      return Intl.DateTimeFormat("ru", {
        hour: "numeric",
        minute: "numeric",
      }).format(new Date(date));
    },
    handleScroll() {
      const container = this.$refs.scrollContainer;
      if (
          container?.scrollTop + container?.clientHeight >=
          container?.scrollHeight - 1 &&
          !this.maxNotifications
      ) {
        this.LoadMore();
      }
    },
    LoadMore() {
      this.page = this.page + 1;
      api.getNotifications(this.page).then((res) => {
        const data = Object.values(res.data).flat();

        if (data.length) {
          this.notifications = this.notifications.concat(data);
        } else {
          this.maxNotifications = true;
        }
      });
    },
    loadNotifications() {
      api.getNotifications(0).then((res) => {
        this.notifications = Object.values(res.data).flat();
      });
    },
    getActiveMenu(isActive) {
      this.burgerIsActive = isActive;
    },
    closeModal() {
      this.isNoticesOpen = !this.isNoticesOpen;

      if (this.isNoticesOpen) {
        document.documentElement.style.overflow = "hidden";
      } else {
        document.documentElement.style.overflow = "auto";
      }
      this.isCheckBoxNotificationsEnabled =
          this.$store.state.auth.accountCurrent.settings.pushNotificationsEnabled;
      this.isCheckBoxMessagesEnabled =
          this.$store.state.auth.accountCurrent.settings.pushMessagesEnabled;
    },
    onClickSubmitNotificationsSelection() {
      this.onClickToggleNotifications()
      this.closeModal()
    },
    getSystemsNotification(a) {
      this.isCheckBoxNotificationsEnabled = a;
    },
    getMessagesNotification(a) {
      console.log(a)
      this.isCheckBoxMessagesEnabled = a;
    },
    onClickToggleNotifications() {
      axios.post("account/edit", {
        settings: {
          pushNotificationsEnabled: this.isCheckBoxNotificationsEnabled,
          pushMessagesEnabled: this.isCheckBoxMessagesEnabled,
        },
      })
          .then((res) => {
            this.$store.dispatch("auth/getEditAccountCurrent", res);
            this.isNotificationsEnabled =
                res.data.settings.pushNotificationsEnabled;
            this.isMessagesEnabled = res.data.settings.pushMessagesEnabled;
          })
          .catch((error) => {
            console.error(error);
          });
    },
  },
  mounted() {
    this.$store.dispatch("auth/getAccountCurrent");
    this.isCheckBoxNotificationsEnabled =
        this.$store.state.auth.accountCurrent.settings.pushNotificationsEnabled;
    this.isCheckBoxMessagesEnabled =
        this.$store.state.auth.accountCurrent.settings.pushMessagesEnabled;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/theme/default/colors.scss";

.switch-toggle {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 34px;
  margin: 15px;
}

.toggle {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 3px;
  left: 0;
  right: 0;
  bottom: -3px;
  width: 68px;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.toggle:checked + .slider {
  background-color: #ffc107;
}

.toggle:checked + .slider:before {
  -webkit-transform: translateX(33px);
  -ms-transform: translateX(33px);
  transform: translateX(33px);
}

header {
  background: rgba(255, 206, 56, 1);
  border-bottom: 10px solid #4f4f4f;
  height: 130px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  z-index: 9;

  .menu {
    display: flex;
    flex-direction: row;
  }

  .user-wrapper__name {
    font-family: Nunito, sans-serif;
    font-size: 16px;
    line-height: 20px;
  }

  .notices {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &__img {
      position: relative;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 78px;
      height: 78px;
      border: 10px solid #ffce38;
      border-radius: 50%;
      box-shadow: #e89b43 0px 0px 5px;
    }

    &__number {
      position: absolute;
      color: white;
    }

    &-wrapper {
      z-index: 3;
      margin-right: -95px;
      display: flex;
      position: relative;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .notices-mobile {
    display: none;
    left: -15px;
    z-index: 10;
    position: absolute;
    align-items: center;
    justify-content: center;

    &__img {
      position: relative;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 38px;
      height: 38px;
      border: 3px solid #ffce38;
      border-radius: 50%;
      box-shadow: #e89b43 0px 0px 5px;

      img {
        width: 20px;
      }
    }

    &__number {
      position: absolute;
      color: white;
    }

    &-wrapper {
      position: relative;
    }

    @media (max-width: 768px) {
      display: flex;
    }
  }

  .icon-takeout-wrapper-up {
    z-index: 3;
    position: relative;
    margin-top: -25px;
    margin-right: -10px;
    min-width: 132px;
    width: 132px;
    height: 100px;
  }
  .icon-takeout-wrapper-up svg {
    z-index: 5;
  }

  .burger-menu-wrapper .icon-takeout-wrapper-up {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .icon-takeout-wrapper-down {
    display: none;
    position: absolute;
    min-width: 132px;
    width: 127px;
    top: 28px;
    left: 60px;
    height: 215px;
    @media (max-width: 768px) {
      display: block;
    }
  }

  .user {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    border-radius: 40px;
    @media (max-width: 768px) {
      z-index: 5;
    }

    .messenger {
      position: absolute;
      content: "";
      bottom: -15px;
      right: 0;
    }

    &__name {
      font-family: Nunito, sans-serif;
      padding: 10px;
      max-width: 250px;
      color: black;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    &__img {
      min-width: 78px;
      max-width: 78px;
      height: 78px;
      border: 10px solid #ffce38;
      border-radius: 50%;
      box-shadow: #e89b43 0px 0px 5px;
      img {
        border-radius: 40px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-wrapper {
      margin-left: -100px;
      display: flex;
    }
  }

  .navbar {
    transition: all 0.5s ease;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    padding: 20px 0px 50px 0px;
    // background-color: $white;
    @media (max-width: 768px) {
      position: fixed;
      top: 20px;
      padding: 0 33px;
      left: 0;
      right: 0;
    }
    &-burger {
      @media (max-width: 768px) {
        padding: 0 5px;
      }
    }
  }

  @media (max-width: 768px) {
    background: white;
    border-bottom: none;
  }
}

.burger-menu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 78px;
  width: 78px;
  background: white;

  border-radius: 50%;
  border: 10px solid #ffce38;
  box-shadow: #e89b43 0px 0px 5px;
  @media (max-width: 768px) {
    z-index: 5;
  }

  &--component {
    position: relative;
    z-index: 10000;
    max-width: 350px;
    background: transparent;

    &.active {
      max-height: calc(100vh);
      overflow-y: auto;
    }
  }

  &-wrapper {
    z-index: 2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 350px;

    @media (max-width: 768px) {
      margin-right: 20px;
    }
  }

  @media (max-width: 768px) {
    height: 68px;
    width: 68px;
  }
}
</style>
